import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  IParamTransactionSucad,
  ITransactionSucad,
  ITransactionSucadRequestObject,
} from "types/endpoints/transactionSucad";

export const transactionSucadStoreAPI = createApi({
  reducerPath: "transactionSucadStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/transaction-sucad`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["TransactionSucadList"],
  endpoints: (builder) => {
    return {
      getTransactionSucad: builder.query<
        APaginationEntity<ITransactionSucad[]>,
        BackendPaginationRequestObject<Partial<IParamTransactionSucad>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<ITransactionSucad[]>,
        ) => response.data,
        providesTags: ["TransactionSucadList"],
      }),

      getTransactionSucadbyId: builder.query<
        ITransactionSucad,
        Partial<ITransactionSucad>
      >({
        query: ({ id }) => {
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ITransactionSucad>) =>
          response.data,
        providesTags: ["TransactionSucadList"],
      }),
      createTransactionSucad: builder.mutation<
        BackendDataShape<object>,
        ITransactionSucadRequestObject
      >({
        query: (obj) => {
          return {
            url: "",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["TransactionSucadList"],
      }),
      updateTransactionSucad: builder.mutation<
        BackendDataShape<object>,
        Partial<ITransactionSucadRequestObject & { id: number }>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["TransactionSucadList"],
      }),

      deleteTransactionSucad: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["TransactionSucadList"],
      }),
    };
  },
});

export const {
  useGetTransactionSucadQuery,
  useGetTransactionSucadbyIdQuery,
  useCreateTransactionSucadMutation,
  useUpdateTransactionSucadMutation,
  useDeleteTransactionSucadMutation,
  util: { resetApiState: resetTransactionSucadStoreAPI },
} = transactionSucadStoreAPI;
