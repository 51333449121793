import React from "react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { puspenerballogo } from "../../assets/images";
import { widthBreakpoints } from "../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { toggleSidebar } from "../../store/sidebarStore";
import SidebarItem, {
  SidebarItemProps as SidebarItemProps,
} from "./SidebarItem";
import useSidebarStyles from "./style";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";

interface Props {
  sidebarItems: SidebarItemProps[];
}

const StyledNavigationItemWrapper = styled.div`
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 3px solid #1c1c24;
  }
  overflow-y: auto;
`;

const Sidebar: React.FunctionComponent<Props> = ({ sidebarItems: nav }) => {
  const classes = useSidebarStyles();
  // const navigate = useNavigate();
  const expand = useAppSelector(
    ({ sidebarStore }) => sidebarStore.sidebarExpand,
  );
  const dispatch = useAppDispatch();

  const dimension = useWindowDimensions();

  const isGreaterThan768 = useMediaQuery("(min-width: 768px)");

  React.useEffect(() => {
    if (isGreaterThan768) {
      dispatch(toggleSidebar(dimension.width > widthBreakpoints[2]));
    }
    // eslint-disable-next-line
  }, [isGreaterThan768]);

  return (
    <div className={[classes.container, expand ? "expand" : ""].join(" ")}>
      <div
        className={[classes.logoContainer, expand ? "expand" : ""].join(" ")}
      >
        <div
          className={[classes.sidebarLogoWrapper, expand ? "expand" : ""].join(
            " ",
          )}
        >
          <img
            src={puspenerballogo}
            style={{ width: expand ? 120 : 40 }}
            alt="puspenerballogo"
          />
        </div>
      </div>
      {/* Navigations Container */}
      <StyledNavigationItemWrapper>
        {nav.map((props) => {
          return <SidebarItem key={props.title} {...props} />;
        })}
      </StyledNavigationItemWrapper>
      <div
        className={[classes.footer, expand ? "expand" : ""].join(" ")}
        onClick={() => dispatch(toggleSidebar())}
      >
        {expand ? <BsChevronLeft /> : <BsChevronRight />}
      </div>
    </div>
  );
};

export default Sidebar;
