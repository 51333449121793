import { useGetSucadMasterQuery } from "store/masterDataSucadStore";

import { BackendPaginationRequestObject, BasicSelectOpt } from "types";

interface ReturnVal {
  arr: BasicSelectOpt<number>[];
  isLoading: boolean;
}

interface SucadOptionProps extends BackendPaginationRequestObject<object> {
  search?: string;
}

const useSucadOpts = ({
  page = 1,
  take = 10,
  search,
}: SucadOptionProps): ReturnVal => {
  const {
    data,
    isLoading: loading,
    isFetching,
  } = useGetSucadMasterQuery({ page, take, search });
  const isLoading = loading || isFetching;

  if (!data || data.entities.length < 1)
    return {
      arr: [],
      isLoading,
    };
  return {
    arr: data.entities.map((val) => {
      return {
        label: val.partNumber ?? "",
        value: val.id,
      };
    }),
    isLoading,
  };
};

export default useSucadOpts;
