import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { IGetGudangParams, IGudang } from "types/endpoints/warehouse";
import { APaginationEntity, BackendPaginationRequestObject } from "types";

export const gudangStoreAPI = createApi({
  reducerPath: "gudangStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["GudangList"],
  endpoints: (builder) => {
    return {
      getGudang: builder.query<
        APaginationEntity<IGudang[]>,
        BackendPaginationRequestObject<Partial<IGetGudangParams>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/gudang" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: APaginationEntity<IGudang[]>) => {
          return response;
        },
        providesTags: ["GudangList"],
      }),
    };
  },
});

export const {
  useGetGudangQuery,
  util: { resetApiState: resetGudangStoreAPI },
} = gudangStoreAPI;
