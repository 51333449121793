import React, { ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { PulseLoader } from "react-spinners";

interface Props {
  isLoading?: boolean;
  containerClassName?: string;
  children?: ReactNode;
}

const useStyles = createUseStyles({
  container: {
    // zIndex: -1,
    position: "relative",
    display: "flex",
    "&>*": {
      flex: 1,
      // height: "100%",
    },
  },
});

const LoadingContainer: React.FunctionComponent<Props> = ({
  children,
  isLoading,
  containerClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={[classes.container, containerClassName].join(" ")}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "calc(50% - 20px)",
            bottom: "calc(50% - 20px)",
            left: "calc(50% - 60px)",
            right: "calc(50% - 60px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <PulseLoader size={20} color="#1486DC" loading={!!isLoading} />
        </div>
      )}
      {children}
    </div>
  );
};

export default LoadingContainer;
