import { useGetPesudMasterQuery } from "store/masterDataPesudStore";

import { BackendPaginationRequestObject, BasicSelectOpt } from "types";

interface ReturnVal {
  arr: BasicSelectOpt<number>[];
  isLoading: boolean;
}

interface PesudOptionProps extends BackendPaginationRequestObject<object> {
  kind?: string;
}

const usePesudOpts = ({
  page = 1,
  take = 10,
  kind,
}: PesudOptionProps): ReturnVal => {
  const {
    data,
    isLoading: loading,
    isFetching,
  } = useGetPesudMasterQuery({ page, take, kind });
  const isLoading = loading || isFetching;

  if (!data || data.entities.length < 1)
    return {
      arr: [],
      isLoading,
    };
  return {
    arr: data.entities.map((val) => {
      return {
        label: val.kind,
        value: val.id,
      };
    }),
    isLoading,
  };
};

export default usePesudOpts;
