import { FC } from "react";
import QRCode from "react-qr-code";

import { StyledButton } from "components/Modal/utils/modal.style";

import { COLORS } from "assets/theme";

import { IMasterSucad } from "types/endpoints/sucad";

interface Props {
  text: string;
  onHide: () => void;
  data?: IMasterSucad | undefined;
}

const QRCodeContent: FC<Props> = (props) => {
  const { text, onHide, data } = props;

  return (
    <div
      style={{
        background: COLORS.dark_grey_3,
        color: COLORS.white,
      }}
    >
      <div
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 200,
          width: "100%",
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <QRCode
          size={200}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={text}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <div style={{ fontWeight: 600, fontSize: "12px" }}>
          {data?.partNumber}
        </div>
        <div style={{ fontSize: "14px" }}>{data?.name}</div>
      </div>

      {/* Some day this code will be used */}
      {/* <StyledButton
        style={{
          width: "100%",
          marginBottom: "12px",
          backgroundColor: COLORS.white,
          color: COLORS.black_3,
        }}
        type="button"
        onClick={() => {
          onHide();
        }}
      >
        GENERATE ULANG QR
      </StyledButton> */}
      <StyledButton
        type="button"
        className="print-hide"
        style={{ width: "100%", backgroundColor: COLORS.green_1_puspenerbal }}
        onClick={() => {
          window.print();
          onHide();
        }}
      >
        DOWNLOAD/PRINT QR
      </StyledButton>
    </div>
  );
};

export default QRCodeContent;
