export const ListOfRoutes = {
  homepage: "/",
  loginpage: "/login",
  dashboard: "/dashboard",
  management: "/management",
  profile: "/profile",
  master: "/master",
} as const;

export class SisfologRoutes {
  static get home(): string {
    return `/` as const;
  }

  static get dashboard(): string {
    return `/dashboard` as const;
  }
  static get dashboard_overview(): string {
    return `${this.dashboard}/overview` as const;
  }
  static get alert(): string {
    return `/alert` as const;
  }

  static get login(): string {
    return `/login` as const;
  }

  static get logout(): string {
    return `/logout` as const;
  }

  static get account(): string {
    return `/account` as const;
  }

  static get account_changePwd(): string {
    return `${this.account}/change-password`;
  }

  static get account_getOTP(): string {
    return `${this.account}/otp`;
  }

  static get report(): string {
    return `/report` as const;
  }

  static get management(): string {
    return `/management` as const;
  }

  static get master(): string {
    return `/master` as const;
  }

  static get integrasiRFID(): string {
    return `${this.management}/integrasi-rfid`;
  }

  static get management_account(): string {
    return `${this.management}/account` as const;
  }
  static get management_workflow(): string {
    return `${this.management}/workflow` as const;
  }

  static get notification(): string {
    return "/notification";
  }

  static get pesudManagement(): string {
    return `${this.management}/pesud` as const;
  }

  static getViewPesudManagement(id: number): string {
    return `${this.pesudManagement}/view/${id}` as const;
  }

  static get materialManagement(): string {
    return `${this.management}/material` as const;
  }

  static get sucadManagement(): string {
    return `${this.management}/sucad` as const;
  }

  static get materialComponentManagement(): string {
    return `${this.management}/component` as const;
  }
  static get sparePartManagement(): string {
    return `${this.management}/sparepart` as const;
  }
  static get warehouseManagement(): string {
    return `${this.management}/warehouse` as const;
  }
  static get maintenanceManagement(): string {
    return `${this.management}/maintenance` as const;
  }
  static get warehousePicManagement(): string {
    return `${this.management}/pic-warehouse` as const;
  }

  static get checklistManagement(): string {
    return `${this.management}/checklist` as const;
  }

  static get distributionManagement(): string {
    return `${this.management}/distribusi` as const;
  }

  static get transactionManagement(): string {
    return `${this.management}/transaction` as const;
  }

  static get fileManagement(): string {
    return `${this.management}/file` as const;
  }

  static get materialManagement_active(): string {
    return `${this.materialManagement}/active` as const;
  }
  static get materialComponentManagement_active(): string {
    return `${this.materialComponentManagement}` as const;
  }

  static get materialManagement_inactive(): string {
    return `${this.materialManagement}/inactive` as const;
  }

  static get management_material_view(): string {
    return `${this.materialManagement}/view` as const;
  }

  static get management_warehouse_workflow(): string {
    return `${this.management}/workflow_warehouse` as const;
  }

  static getSpecificMaterial(id: number): string {
    return `${this.management_material_view}/${id}` as const;
  }
  static getSpecificMaterialComponent(id: string | number): string {
    return `${this.materialComponentManagement}/${id}` as const;
  }
  static getSpecificSparepart(id: string | number): string {
    return `${this.sparePartManagement}/${id}` as const;
  }

  static getEditMaterialPage(id: number): string {
    return `${this.management_material_view}/${id}/edit` as const;
  }

  static get backup_restore(): string {
    return `${this.home}backup-restore` as const;
  }

  static get backup(): string {
    return `${this.backup_restore}/backup` as const;
  }

  static get restore(): string {
    return `${this.backup_restore}/restore` as const;
  }

  static get backup_material(): string {
    return `${this.backup_restore}/material` as const;
  }

  static get backup_komponen(): string {
    return `${this.backup_restore}/component` as const;
  }

  static get backup_suku_cadang(): string {
    return `${this.backup_restore}/sparepart` as const;
  }

  static get pesudMaster(): string {
    return `${this.master}/pesud` as const;
  }

  static getEditPesudMaster(id: number): string {
    return `${this.pesudMaster}/edit/${id}` as const;
  }

  static getViewPesudMaster(id: number): string {
    return `${this.pesudMaster}/view/${id}` as const;
  }

  static getAddPesudMaster(): string {
    return `${this.pesudMaster}/add` as const;
  }

  static get sucadMaster(): string {
    return `${this.master}/sucad` as const;
  }
}
