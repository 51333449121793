import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { APesud, APesudResponse } from "types/endpoints/managementPesudData";

export const pesudStoreAPI = createApi({
  reducerPath: "pesudStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/pesud`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PesudList"],
  endpoints: (builder) => {
    return {
      restorePesud: builder.mutation<
        object,
        {
          file: File[];
        }
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/restore",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudList"],
      }),

      getPesud: builder.query<
        APaginationEntity<APesud[]>,
        BackendPaginationRequestObject<Partial<APesud>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APesud[]>) =>
          response.data,
        providesTags: ["PesudList"],
      }),

      getPesudById: builder.query<APesudResponse, Partial<APesudResponse>>({
        query: ({ id }) => {
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: any) => response,
        providesTags: ["PesudList"],
      }),

      getPesudPagination: builder.query<
        APaginationEntity<APesud[]>,
        BackendPaginationRequestObject<Partial<APesud>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APesud[]>) => {
          const { entities, meta } = response.data;
          let startIndex = (meta.page - 1) * meta.offset;
          let no = startIndex;
          const materials = entities.map(({ noMaterial, id, ...rest }) => {
            no++;
            return {
              ...rest,
              id,
              noMaterial: no,
            };
          });
          return {
            entities: materials,
            meta,
          };
        },
        providesTags: (_, __, { id }) => [
          "PesudList",
          { type: "PesudList", id },
        ],
      }),

      createPesud: builder.mutation<Pick<APesud, "id">[], Partial<APesud>>({
        query: (obj) => {
          return {
            url: "",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudList"],
      }),

      updatePesud: builder.mutation<BackendDataShape<object>, Partial<APesud>>({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PesudList"],
      }),

      deletePesud: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PesudList"],
      }),
    };
  },
});

export const {
  useRestorePesudMutation,
  useCreatePesudMutation,
  useGetPesudQuery,
  useGetPesudPaginationQuery,
  useDeletePesudMutation,
  useUpdatePesudMutation,
  useGetPesudByIdQuery,
  util: { resetApiState: resetPesudStoreAPI },
} = pesudStoreAPI;
