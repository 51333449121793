import {
  Action,
  configureStore,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import userStore from "./userStore";
import sidebarStore from "./sidebarStore";
import { userStoreAPI } from "./userStore/userStoreAPI";
import { personelStoreAPI } from "./personelStore/personelStoreAPI";
import { usertypeStoreAPI } from "./usertypeStore/usertypeStoreAPI";
import { toast } from "react-toastify";
import { UnknownAsyncThunkRejectedWithValueAction } from "@reduxjs/toolkit/dist/matchers";
import { ErrorMessageBackendDataShape } from "../types";
import { materialStoreAPI } from "./materialStore/materialStroreAPI";
import { detailMaterialStoreAPI } from "./materialStore/materialDetailStoreAPI";
import { satkerStoreAPI } from "./satkerStore/satkerStoreAPI";
import { dailyCheckupStoreAPI } from "./dailyCheckupStore/dailyCheckupStoreAPI";
import { masterDataStoreAPI } from "./masterDataStore";
import { warehouseItemStoreAPI } from "./warehouseItemStore";
import { notificationStoreAPI } from "./notificationStore/notificationStoreAPI";
import { pesudMasterStoreAPI } from "./masterDataPesudStore";
import { pesudStoreAPI } from "./managementDataPesudStore";
import { dashboardStoreAPI } from "./dashboardStore";
import { masterRFIDStoreAPI } from "./masterDataRFIDStore";
import { sucadMasterStoreAPI } from "./masterDataSucadStore";
import { warehouseStoreAPI } from "./warehouseStore";
import { managementSucadStoreAPI } from "./managementSucadStore";
import { transactionSucadStoreAPI } from "./transactionSucadStore";
import { gudangStoreAPI } from "./gudangStore/indes";
import { stockSucadStoreAPI } from "./stockSucadStore";

export const store = configureStore({
  /**
   * @todo sort these reducer slices alphabetically, ascending.
   */
  reducer: {
    userStore,
    sidebarStore,
    [masterDataStoreAPI.reducerPath]: masterDataStoreAPI.reducer,
    [warehouseItemStoreAPI.reducerPath]: warehouseItemStoreAPI.reducer,
    [warehouseStoreAPI.reducerPath]: warehouseStoreAPI.reducer,
    [managementSucadStoreAPI.reducerPath]: managementSucadStoreAPI.reducer,
    [personelStoreAPI.reducerPath]: personelStoreAPI.reducer,
    [userStoreAPI.reducerPath]: userStoreAPI.reducer,
    [usertypeStoreAPI.reducerPath]: usertypeStoreAPI.reducer,
    [materialStoreAPI.reducerPath]: materialStoreAPI.reducer,
    [detailMaterialStoreAPI.reducerPath]: detailMaterialStoreAPI.reducer,
    [satkerStoreAPI.reducerPath]: satkerStoreAPI.reducer,
    [dailyCheckupStoreAPI.reducerPath]: dailyCheckupStoreAPI.reducer,
    [notificationStoreAPI.reducerPath]: notificationStoreAPI.reducer,
    [pesudMasterStoreAPI.reducerPath]: pesudMasterStoreAPI.reducer,
    [pesudStoreAPI.reducerPath]: pesudStoreAPI.reducer,
    [dashboardStoreAPI.reducerPath]: dashboardStoreAPI.reducer,
    [masterRFIDStoreAPI.reducerPath]: masterRFIDStoreAPI.reducer,
    [sucadMasterStoreAPI.reducerPath]: sucadMasterStoreAPI.reducer,
    [transactionSucadStoreAPI.reducerPath]: transactionSucadStoreAPI.reducer,
    [gudangStoreAPI.reducerPath]: gudangStoreAPI.reducer,
    [stockSucadStoreAPI.reducerPath]: stockSucadStoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo remove this logger if no longer needed
     */
    const logMiddleWare: Middleware = () => {
      return (next) => {
        return (action) => {
          if (isRejectedWithValue(action)) {
            const act = action as UnknownAsyncThunkRejectedWithValueAction;
            const payload = act.payload;

            const isBEErrorPayload = (
              payload: any,
            ): payload is ErrorMessageBackendDataShape => {
              return payload?.data?.status && payload.data.status === "error";
            };
            if (isBEErrorPayload(payload)) {
              toast.error(payload.data.message);
            }
          }
          return next(action);
        };
      };
    };

    /**
     * @todo sort these middlewares alphabetically, ascending.
     */
    let middlewares = getMiddleWares().concat([
      logMiddleWare,
      masterDataStoreAPI.middleware,
      warehouseItemStoreAPI.middleware,
      warehouseStoreAPI.middleware,
      managementSucadStoreAPI.middleware,
      personelStoreAPI.middleware,
      userStoreAPI.middleware,
      usertypeStoreAPI.middleware,
      materialStoreAPI.middleware,
      detailMaterialStoreAPI.middleware,
      satkerStoreAPI.middleware,
      notificationStoreAPI.middleware,
      dailyCheckupStoreAPI.middleware,
      pesudMasterStoreAPI.middleware,
      pesudStoreAPI.middleware,
      dashboardStoreAPI.middleware,
      masterRFIDStoreAPI.middleware,
      sucadMasterStoreAPI.middleware,
      transactionSucadStoreAPI.middleware,
      gudangStoreAPI.middleware,
      stockSucadStoreAPI.middleware,
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
