import * as yup from "yup";

const alternativePartIdSchema = yup.object().shape({
  value: yup.number(),
  label: yup.string(),
});

const masterDataSucadValidationSchema = yup.object().shape({
  pesudId: yup.object().shape({
    value: yup.number().required("Satker wajib diisi"),
    label: yup.string(),
  }),
  unit: yup.object().shape({
    value: yup.string().required("Satuan wajib diisi"),
    label: yup.string(),
  }),
  partNumber: yup.string().required("Part Number wajib diisi"),
  name: yup.string().required("Nama barang wajib diisi"),
  alternativePartId: yup.array().of(alternativePartIdSchema).nullable(),
  kind: yup.string(),
  kelas: yup.string(),
});

export { masterDataSucadValidationSchema };
