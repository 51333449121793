import React, { Dispatch, SetStateAction, useMemo } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { COLORS } from "../../../assets/theme";
import { BackendMetaPagination, PaginationRequestObj } from "../../../types";
import { basicTableUseStyles } from "./BasicTable";

export interface CustomPaginationProps extends Required<PaginationRequestObj> {
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  meta: BackendMetaPagination;
  tableName?: string;
}

const CustomPaginationTable = (props: CustomPaginationProps) => {
  const {
    page = 1,
    setLimit,
    setPage,
    take: limit = 10,
    meta,
    tableName,
  } = props;
  const { itemCount } = meta;
  const classes = basicTableUseStyles();

  const pageCount = useMemo(() => {
    return Math.ceil(itemCount / limit);
  }, [itemCount, limit]);

  React.useEffect(() => {
    if (page && page < 1) {
      setPage(1);
    }
  }, [page]);

  return (
    <div
      style={{
        display: "inline-flex",
        margin: "20px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <span style={{ marginRight: 4, color: COLORS.dark_grey_2 }}>
          Tampilkan&nbsp;
        </span>
        <select
          value={limit}
          className={classes.pageBtn}
          onChange={(e) => {
            setLimit(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize} style={{ color: "black" }}>
              {pageSize} Baris
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        {tableName ? (
          <span style={{ color: COLORS.dark_grey_2 }}>
            Memperlihatkan {limit} dari {itemCount} {tableName}
            &nbsp;
          </span>
        ) : (
          <span style={{ color: COLORS.dark_grey_2 }}>
            Halaman&nbsp;
            {page} dari {pageCount}
            &nbsp;
          </span>
        )}
      </div>
      <div
        style={{
          float: "right",
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        &nbsp;
        <button
          title="Previous"
          onClick={() => setPage((prev) => prev - 1)}
          disabled={page <= 1}
          className={classes.paginationWrapperStyle}
        >
          <MdChevronLeft size={16} />
        </button>
        &nbsp;
        <span>
          <input
            style={{ width: "42px" }}
            className={classes.paginationWrapperStyle}
            type="number"
            value={page ? page : 1}
            min={1}
            max={pageCount}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 0 : 1;
              //   gotoPage(page);
              setPage(page);
            }}
          />
        </span>
        &nbsp;
        <button
          title="Next"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={page === pageCount}
          className={classes.paginationWrapperStyle}
        >
          <MdChevronRight size={16} />
        </button>
        &nbsp; &nbsp;
      </div>
    </div>
  );
};

export default CustomPaginationTable;
