import React, {
  ChangeEvent,
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import {
  useTable,
  usePagination,
  useRowSelect,
  useExpanded,
  Column,
  CellProps,
  Renderer,
  Row,
  TableState,
  IdType,
  useSortBy,
  useAbsoluteLayout,
} from "react-table";
import { CSVLink } from "react-csv";
import TableContainer from "../../atoms/Card/TableContainer";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import { createUseStyles, useTheme } from "react-jss";
import { BasicTheme, COLORS } from "../../../assets/theme";
import { GoPlus } from "react-icons/go";
import { MdChevronLeft, MdChevronRight, MdOutlineSearch } from "react-icons/md";
import { BaseIcon } from "../../../assets/icons";
import { sortIcon_svg } from "../../../assets/icons/svg";
import { MdOutlineDownload } from "react-icons/md";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { FaChevronDown } from "react-icons/fa";
import { addAlphatoHexColor } from "../../../assets/usefulFunctions";
import { CustomPaginationProps } from "./CustomPaginationTable";
import { CircularProgress } from "@mui/material";

export const basicTableUseStyles = createUseStyles((theme: BasicTheme) => {
  return {
    pageBtn: {
      border: `0.5px solid ${theme.lightGray}`,
      backgroundColor: "transparent",
      padding: "0.6rem 0.7rem",
      borderRadius: 6,
      color: "white",
    },
    paginationWrapperStyle: {
      padding: "0.6rem 0.7rem",
      borderRadius: 6,
      border: `1px solid ${COLORS.grey}`,
      background: "transparent",
      color: COLORS.white,
      "&:disabled": {
        background: addAlphatoHexColor(COLORS.light_grey_2, 0.2),
      },
    },
    nextBtn: {
      border: `0.5px solid ${theme.lightGray}`,
      backgroundColor: theme.backgroundColor,
      padding: "3px 8px",
      borderRadius: "4px",
    },
    inputBtn: {
      border: `0.5px solid ${theme.lightGray}`,
      backgroundColor: theme.backgroundColor,
      padding: "3px",
      width: "8px",
      borderRadius: "4px",
      "&:onfocus": {
        border: `0.5px solid ${theme.lightGray}`,
      },
    },

    tabelBorder: {
      paddingTop: "0em",
      paddingBottom: "0em",
      paddingRight: "2em",
      "&:lastChild": {
        border: "0em",
      },
    },
    inputWrapper: {
      backgroundColor: theme.white,
      borderRadius: "0.75rem",
      overflow: "hidden",
      border: "none",
    },
    input: {
      backgroundColor: theme.backgroundColor,
      border: "none",
      padding: "1rem",
      "&:focus": {
        color: theme.black_1,
        backgroundColor: theme.backgroundColor,
        borderLeft: "none",
        outline: "none",
        boxShadow: "none",
      },
    },
    svg: {
      backgroundColor: theme.backgroundColor,
      color: theme.darkGray,
      paddingLeft: "1.25rem",
      border: "none",
    },
    tableContainer: {
      flex: 1,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        border: "6px solid transparent",
      },
    },
  };
});

interface Props<D extends object> {
  data: readonly D[];
  columns: Column<D>[];
  onView?: (arg0: any) => any;
  noGeneralAction?: boolean;
  notSelectable?: boolean;
  noTableAction?: boolean;
  renderAction?: (row: Row<D>, arg1: number) => any;
  onAdd?: (arg0: any) => void;
  onAddGroup?: (arg0: any) => void;
  onManageGroup?: (arg0: any) => void;
  noAddButton?: boolean;
  onExportButton?: boolean;
  isDisabled?: boolean;
  addText?: string;
  addHeaderRightChildren?: ReactNode;
  noOnSearch?: boolean;
  exportData?: string[][] | D[];
  exportText?: string;
  renderNoExpansion?: (args: Row<D>) => boolean;
  renderExpansion?: (row: Row<D>) => JSX.Element;
  renderDataExpansion?: (row: Row<D>) => JSX.Element;
  noPagination?: boolean;
  addTitle?: JSX.Element | string;
  hasTitle?: boolean;
  notNumbering?: boolean;
  header?: string[];
  hideCols?: IdType<D>[];
  topChildren?: ReactNode;
  setSelectedRows?: (originalRows: D[]) => void;
  setSelectedRowIds?: (selectedIds: Record<IdType<D>, boolean>) => void;
  isLoading?: boolean;
  renderNoData?: (isLoading?: boolean) => JSX.Element;
  renderDataSelected?: readonly D[];
  csvFileName?: string;
  tableContainerStyle?: CSSProperties;
  tableStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  cellStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  searchInput?: string;
  placeholderSearchInput?: string;
  setSearchInput?: Dispatch<SetStateAction<string>>;
  getTrPropsStyle?: (args: Row<D>) => CSSProperties;
  tableClassName?: string;
  customPagination?: React.ReactElement<CustomPaginationProps>;
}

export type BasicTableProps<D extends object> = Props<D>;

const BasicNoDataComponent: React.FunctionComponent<{
  isLoading?: boolean;
}> = ({ isLoading }) => {
  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={24}
        color="inherit"
        style={{ color: COLORS.blue_1_puspenerbal }}
      />
    </div>
  ) : (
    <div>
      <p style={{ textAlign: "center", color: "white", margin: 10 }}>
        Tidak Ada Data
      </p>
    </div>
  );
};

const BasicTable = <D extends object>({
  columns,
  data,
  onAdd,
  getTrPropsStyle,
  noAddButton: onAddButton,
  isDisabled,
  addText = "Add Item",
  exportData,
  onExportButton,
  exportText = "Export to CSV",
  renderExpansion,
  renderNoExpansion,
  renderDataExpansion,
  notNumbering,
  noGeneralAction,
  noTableAction,
  notSelectable,
  noPagination,
  renderAction,
  hideCols,
  isLoading,
  noOnSearch,
  addHeaderRightChildren,
  addTitle,
  hasTitle,
  cellStyle,
  setSelectedRows,
  setSelectedRowIds,
  header,
  topChildren,
  tableContainerStyle,
  headerStyle,
  bodyStyle,
  setSearchInput,
  placeholderSearchInput,
  searchInput,
  tableStyle,
  tableClassName,
  renderNoData = (isLoading) => <BasicNoDataComponent isLoading={isLoading} />,
  csvFileName,
  customPagination,
}: Props<D>) => {
  const generateInitialState = ({
    expandable,
    selectable,
    expandDetail,
    numbering,
  }: {
    expandable: boolean;
    selectable: boolean;
    numbering: boolean;
    expandDetail: boolean;
    hideCols?: IdType<D>[];
  }): Partial<TableState<D>> => {
    let hiddenColumns: IdType<D>[] = [];
    if (!expandable) hiddenColumns.push("expander");
    if (!selectable) hiddenColumns.push("selection");
    if (!expandDetail) hiddenColumns.push("expandDetail");
    if (!numbering) hiddenColumns.push("no");
    if (hideCols) hiddenColumns.push(...hideCols);
    return {
      hiddenColumns,
    };
  };
  const classes = basicTableUseStyles();
  const theme = useTheme<BasicTheme>();
  const isGreaterThan1024 = useMediaQuery("(min-width: 1024px)");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: generateInitialState({
        selectable: !notSelectable,
        expandable: !!renderExpansion,
        expandDetail: !!renderDataExpansion,
        numbering: !notNumbering,
        hideCols,
      }),
      manualPagination: !!customPagination,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useAbsoluteLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const CellSelectRenderer: Renderer<CellProps<D, any>> = ({ row }) => {
          return (
            <div>
              <IndeterminateCheckbox
                name="hi"
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          );
        };

        const CellNoRenderer: Renderer<CellProps<D, any>> = ({ row }) => {
          return <>{row.index + 1}</>;
        };

        const CellExpandRenderer: Renderer<CellProps<D, any>> = ({ row }) => {
          let noExpansion = renderNoExpansion && renderNoExpansion(row);
          return (
            <span
              {...row.getToggleRowExpandedProps()}
              style={{ cursor: "unset" }}
            >
              {!noExpansion && renderExpansion && (
                <FaChevronDown
                  fontSize={10}
                  scale={10}
                  color={COLORS.grey}
                  cursor="pointer"
                  style={{
                    transition: "all .2s",
                    transform: row.isExpanded ? "rotate(-180deg)" : "",
                  }}
                />
              )}
            </span>
          );
        };

        return [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  name="hi"
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            Cell: CellSelectRenderer,
          },

          {
            id: "no",
            Header: "No",
            Cell: CellNoRenderer,
          },
          ...columns,
          {
            Header: () => <div style={{ width: "30px" }} />,
            id: "expander",
            Cell: CellExpandRenderer,
          },
        ];
      });
    },
  );

  const handleSearchInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (setSearchInput) {
        setSearchInput(e.target.value);
      }
    },
    [setSearchInput],
  );

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map((row) => row.original));
    }
  }, [setSelectedRowIds, selectedRowIds, setSelectedRows, selectedFlatRows]);

  useEffect(() => {
    if (setSelectedRowIds) {
      setSelectedRowIds(selectedRowIds);
    }
  }, [setSelectedRowIds, selectedRowIds]);

  return (
    <TableContainer
      style={{
        border: "none",
        background: "transparent",
        minHeight: "100vh",
        ...tableContainerStyle,
      }}
    >
      {hasTitle && <div style={{ marginLeft: "1.5em" }}>{addTitle}</div>}
      {!noGeneralAction && (
        <div
          style={{
            margin: "1em 1.8em 0.8em 1em",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 8,
          }}
        >
          <div>
            {!noOnSearch && (
              <InputGroup className={classes.inputWrapper}>
                <InputGroupText className={classes.svg}>
                  <MdOutlineSearch fontSize={16} size={16} />
                </InputGroupText>
                <Input
                  className={classes.input}
                  value={searchInput}
                  placeholder={placeholderSearchInput ?? "Search..."}
                  style={{
                    width: isGreaterThan1024 ? "350px" : "200px",
                    flex: 1,
                  }}
                  onChange={(e) => handleSearchInput(e)}
                  type="text"
                />
                <InputGroupText
                  style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.darkGray,
                    paddingLeft: "2.25rem",
                    border: "none",
                  }}
                />
              </InputGroup>
            )}
          </div>
          <div>
            {onExportButton && (
              <CSVLink
                data={exportData ? exportData : []}
                headers={header}
                filename={csvFileName ?? "data.csv"}
              >
                <Button
                  style={{
                    float: "right",
                    marginLeft: "1em",
                    border: "none",
                    background: "#169E50",
                    color: "#FFFFFF",
                    borderRadius: "0.5rem",
                    padding: "1rem 2rem",
                  }}
                  onClick={() => console.log("Exported")}
                >
                  {" "}
                  <MdOutlineDownload
                    fontSize={13}
                    size={13}
                    style={{ marginRight: "0.5rem" }}
                  />{" "}
                  {exportText}
                </Button>
              </CSVLink>
            )}
            {!onAddButton && (
              <Button
                style={{
                  border: "none",
                  float: "right",
                  background: "#3699FF",
                  color: "#FFFFFF",
                  borderRadius: "0.5rem",
                  padding: "1rem 2rem",
                }}
                onClick={onAdd}
                disabled={isDisabled}
              >
                <GoPlus
                  fontSize={13}
                  size={13}
                  style={{ marginRight: "0.5rem" }}
                />{" "}
                {addText}
              </Button>
            )}
            {addHeaderRightChildren}
          </div>
        </div>
      )}
      {topChildren}
      <div className={classes.tableContainer}>
        <LoadingContainer isLoading={false}>
          <table
            {...getTableProps()}
            className={tableClassName}
            style={{
              width: "100%",
              ...tableStyle,
            }}
          >
            <thead
              style={{
                borderBottom: "0.5px solid #C4CDE0",
                borderTop: "0.5px solid #C4CDE0",
                ...headerStyle,
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    // const stickyStyle = {
                    //   position: "sticky",
                    //   left: 0,
                    //   ...headerStyle,
                    // };
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        style={{
                          padding: "1.5rem 1em 1.5rem 2em",
                          fontSize: "10px",
                          background: `${COLORS.gradient_green_table_header}`,
                          fontWeight: 600,
                          textTransform: "uppercase",
                          borderBottom: !column.enableColSpan
                            ? "0px"
                            : "0.5px solid #C4CDE0",
                          // ...(column.sticky ? stickyStyle : headerStyle),
                          ...headerStyle,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            gap: 1,
                            width: "max-content",
                            margin: !column.enableColSpan ? "0px" : "0 auto",
                          }}
                        >
                          {column.render("Header")}

                          <span>
                            {column.isSorted ? (
                              <BaseIcon
                                src={sortIcon_svg}
                                color={COLORS.white}
                                stroke={COLORS.white}
                                strokeWidth={0.3}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                  {!noTableAction && (
                    <th style={{ textAlign: "center" }}>Action</th>
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length && !isLoading ? (
                page.map((row, i) => {
                  prepareRow(row);
                  let { key, style, ...rest } = row.getRowProps();
                  const trPropsStyle = getTrPropsStyle
                    ? getTrPropsStyle(row)
                    : style;
                  return (
                    <React.Fragment key={key}>
                      <tr
                        {...rest}
                        // {...row.getToggleRowExpandedProps()}
                        style={{
                          background: row.isExpanded
                            ? COLORS.gradient_blue_table_expanded_puspenerbal
                            : COLORS.gradient_blue,
                          fontWeight: "normal",
                          color: COLORS.white,
                          // zIndex: "auto",
                          textTransform: "uppercase",
                          ...style,
                          ...bodyStyle,
                          ...trPropsStyle,
                        }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                padding: "1.5rem 1em 1.5rem 2em",
                                textAlign: !cell.column.enableColSpan
                                  ? "left"
                                  : "center",
                                ...cellStyle,
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        {!noTableAction && (
                          <td
                            style={{
                              padding: "1.5em",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {renderAction && renderAction(row, i)}
                          </td>
                        )}
                      </tr>
                      {row.isExpanded && !!renderExpansion ? (
                        <tr
                          style={{
                            background: row.isExpanded
                              ? COLORS.black_2_puspenerbal
                              : COLORS.gradient_blue,
                            color: COLORS.white,
                          }}
                        >
                          <td
                            colSpan={visibleColumns.length}
                            style={{
                              borderLeft: row.isExpanded
                                ? `2px solid ${COLORS.green_1_puspenerbal}`
                                : "inherit",
                            }}
                          >
                            {renderExpansion(row)}
                          </td>
                        </tr>
                      ) : null}
                      {row.isExpanded && !!renderDataExpansion ? (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            {renderDataExpansion(row)}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderNoData(isLoading)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </LoadingContainer>
      </div>
      {!noPagination &&
        (customPagination
          ? customPagination
          : data &&
            data.length > 10 && (
              <div
                className="pagination"
                style={{
                  display: "inline-flex",
                  margin: "20px 0px",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span style={{ marginRight: 4 }}>Tampilkan&nbsp;</span>
                  <select
                    value={pageSize}
                    className={classes.pageBtn}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option
                        key={pageSize}
                        value={pageSize}
                        style={{ color: "black" }}
                      >
                        {pageSize} Baris
                      </option>
                    ))}
                  </select>
                  &nbsp;&nbsp;
                  <span style={{ color: COLORS.dark_grey_2 }}>
                    Halaman&nbsp;
                    {pageIndex + 1} dari {pageOptions.length}
                    &nbsp;
                  </span>
                </div>
                <div
                  style={{
                    float: "right",
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  &nbsp;
                  <button
                    title="Previous"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className={classes.paginationWrapperStyle}
                  >
                    <MdChevronLeft size={16} />
                  </button>
                  &nbsp;
                  <span>
                    <input
                      style={{ width: "42px" }}
                      className={classes.paginationWrapperStyle}
                      type="number"
                      value={pageIndex + 1}
                      min={1}
                      max={pageCount}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                    />
                  </span>
                  &nbsp;
                  <button
                    title="Next"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className={classes.paginationWrapperStyle}
                  >
                    <MdChevronRight size={16} />
                  </button>
                  &nbsp; &nbsp;
                </div>
              </div>
            ))}
    </TableContainer>
  );
};

export default BasicTable;
