import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  ASubmitWarehouseItem,
  AWarehouseItem,
  IWarehousing,
} from "types/endpoints/warehouse";

export const warehouseItemStoreAPI = createApi({
  reducerPath: "warehouseItemStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/warehouse_items`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "WarehouseItemsList",
    "WarehousingList",
    "WarehouseItemsTransactionHistoryList",
    "WarehouseItemsPicList",
    "WarehouseItemsRFIDList",
  ],
  endpoints: (builder) => {
    return {
      getWarehouseItems: builder.query<
        AWarehouseItem[],
        Partial<AWarehouseItem> & { category?: string }
      >({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `` + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["WarehouseItemsList"],
        transformResponse: (response: BackendDataShape<AWarehouseItem[]>) =>
          response.data,
      }),
      getWarehousing: builder.query<
        IWarehousing[],
        Partial<IWarehousing> & {
          search?: string;
          warehouseId?: number;
          pesudId?: number;
        }
      >({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `` + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["WarehousingList"],
        transformResponse: (response: BackendDataShape<IWarehousing[]>) =>
          response.data,
      }),
      createWarehouseItem: builder.mutation<
        BackendDataShape<object>,
        Omit<Partial<ASubmitWarehouseItem>, "id">
      >({
        query: (data) => {
          return {
            url: ``,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["WarehouseItemsList"],
      }),
      updateWarehouseItem: builder.mutation<
        BackendDataShape<object>,
        Partial<ASubmitWarehouseItem>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WarehouseItemsList"],
      }),
      deleteWarehouseItem: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["WarehouseItemsList"],
      }),
    };
  },
});

export const {
  useGetWarehouseItemsQuery,
  useGetWarehousingQuery,
  useCreateWarehouseItemMutation,
  useUpdateWarehouseItemMutation,
  useDeleteWarehouseItemMutation,
  util: { resetApiState: resetWarehouseItemsStore },
} = warehouseItemStoreAPI;
