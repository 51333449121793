import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { ISucadMasterGetRequest } from "types/endpoints/masterSucadData";
import {
  IManagementSucad,
  ISucadManagementRequest,
} from "types/endpoints/sucad";

export const managementSucadStoreAPI = createApi({
  reducerPath: "managementSucadStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/management-sucad`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ManagementSucadList"],
  endpoints: (builder) => {
    return {
      getManagementSucad: builder.query<
        APaginationEntity<IManagementSucad[]>,
        BackendPaginationRequestObject<Partial<ISucadMasterGetRequest>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<IManagementSucad[]>,
        ) => response.data,
        providesTags: ["ManagementSucadList"],
      }),

      getManagementSucadById: builder.query<
        IManagementSucad,
        Partial<IManagementSucad>
      >({
        query: ({ id }) => {
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IManagementSucad>) =>
          response.data,
        providesTags: ["ManagementSucadList"],
      }),
      createManagementSucad: builder.mutation<
        BackendDataShape<object>,
        Partial<ISucadManagementRequest>
      >({
        query: (obj) => {
          return {
            url: "",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ManagementSucadList"],
      }),
      updateManagementSucad: builder.mutation<
        BackendDataShape<object>,
        Partial<IManagementSucad>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ManagementSucadList"],
      }),

      deleteManagementSucad: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["ManagementSucadList"],
      }),
    };
  },
});

export const {
  useGetManagementSucadByIdQuery,
  useGetManagementSucadQuery,
  useCreateManagementSucadMutation,
  useUpdateManagementSucadMutation,
  useDeleteManagementSucadMutation,
  util: { resetApiState: resetManagementSucadStoreAPI },
} = managementSucadStoreAPI;
