import { useCallback, Dispatch, SetStateAction, useMemo } from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";

import { COLORS } from "assets/theme";
import { BaseIcon } from "assets/icons";
import { delete_svg, edit_svg, qrCode_svg } from "assets/icons/svg";

import BasicTable from "components/molecules/Table/BasicTable";
import { StyledFlexResponsive } from "components/StyleComponent/StyleComponent";
import { ConfirmationModalPuspenerbal } from "components/Modal/ConfirmationModalPuspenerbal";
import { useModal } from "components/Modal/utils/useModal";
import StyledToastBodyPuspenerbal from "components/Toast/StyledToastBodyPuspenerbal";
import { tableDefaultStyle } from "components/molecules/Table/tableStyle";
import { StyledSisfoPuspenerbalBlurredWrapper } from "components/PageContainer";
import CustomPaginationTable from "components/molecules/Table/CustomPaginationTable";
import {
  PuspenerbalModal,
  StyledModalContent,
} from "components/molecules/PuspenerbalModal/PuspenerbalModal";

import { useDeleteSucadMasterMutation } from "store/masterDataSucadStore";

import { IMasterSucad } from "types/endpoints/sucad";
import { BackendMetaPagination } from "types";

import SucadForm from "../SucadForm";
import QRCodeContent from "../SucadQR";

type Props = {
  sucadData: IMasterSucad[];
  isLoading: boolean;
  isFetching: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  take: number;
  setLimit: Dispatch<SetStateAction<number>>;
  sucadMeta?: BackendMetaPagination;
};

const SucadTable = ({
  sucadData,
  isLoading,
  isFetching,
  sucadMeta,
  page,
  setPage,
  setLimit,
  take,
}: Props) => {
  const columns = useMemo<Column<IMasterSucad>[]>(() => {
    return [
      {
        Header: "Part Number",
        accessor: "partNumber",
      },
      {
        Header: () => <div style={{ width: "180px" }}>Nama Barang</div>,
        accessor: "name",
      },
      {
        Header: "Pesawat Udara",
        accessor: "pesud",
        id: "1",
        Cell: ({ value }) => {
          return <>{value?.kind}</>;
        },
      },
      {
        Header: "Jenis Pesud",
        accessor: "pesud",
        id: "2",
        Cell: ({ value }) => {
          return <>{value?.kind}</>;
        },
      },
      {
        Header: "Kelas",
        accessor: "pesud",
        id: "3",
        Cell: ({ value }) => {
          return <>{value?.category}</>;
        },
      },
      {
        accessor: "alternativePart",
        Header: "Alternative Part Number",
        Cell: ({ row: { original } }) => {
          const alternativePartNumberData = original.alternativePart
            ? original.alternativePart.map((value) => value.partNumber)
            : ["-"];
          return <>{alternativePartNumberData.join(", ")}</>;
        },
      },
      {
        Header: "Satuan",
        accessor: "satuan",
        Cell: ({ value }) => {
          return <>{value?.name}</>;
        },
      },
      {
        Header: "Aksi",
        accessor: "id",
        id: "action",
        Cell: ({ row: { original }, value }) => {
          const {
            isShown: isDeleteSucadTable,
            toggle: toggleDeleteSucadTable,
          } = useModal();
          const [deleteSucad] = useDeleteSucadMasterMutation();
          const { isShown: isEdit, toggle: toggleEdit } = useModal();
          const { isShown: isShowQR, toggle: toggleShowQR } = useModal();

          const handleDeleteItem = useCallback(
            (event: React.FormEvent) => {
              event.preventDefault();

              deleteSucad({ id: value })
                .unwrap()
                .then(() => {
                  toggleDeleteSucadTable();

                  toast.success(
                    <StyledToastBodyPuspenerbal
                      headerText="Berhasil"
                      description={`Anda berhasil menghapus data Master SUCAD.`}
                    />,
                  );
                })
                .catch(() => {
                  toggleDeleteSucadTable();
                  toast.error(
                    <StyledToastBodyPuspenerbal
                      headerText="Gagal"
                      description={`Anda gagal menghapus data Master SUCAD.`}
                    />,
                  );
                });
            },
            [deleteSucad, toggleDeleteSucadTable, value],
          );

          return (
            <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
              <BaseIcon
                src={qrCode_svg}
                fontSize={"1.2rem"}
                height="16"
                width="16"
                style={{ marginRight: 16 }}
                strokeWidth={0}
                viewBox="0 0 16 16"
                cursor={"pointer"}
                onClick={() => toggleShowQR()}
              />
              <BaseIcon
                src={edit_svg}
                fontSize={"1.2rem"}
                height="16"
                width="16"
                strokeWidth={0}
                viewBox="0 0 16 16"
                color={COLORS.blue_1}
                cursor={"pointer"}
                onClick={() => toggleEdit()}
                style={{ marginRight: 16 }}
              />
              <BaseIcon
                src={delete_svg}
                fontSize={"1.2rem"}
                height="16"
                width="16"
                strokeWidth={0}
                viewBox="0 0 16 16"
                cursor={"pointer"}
                color={COLORS.red}
                onClick={() => toggleDeleteSucadTable()}
              />

              <PuspenerbalModal
                isShown={isShowQR}
                hide={toggleShowQR}
                onCancel={toggleShowQR}
                headerText={"QR Code"}
                modalContent={
                  <QRCodeContent
                    text={String(original.partNumber)}
                    onHide={toggleShowQR}
                    data={original}
                  />
                }
                type="bind"
              />

              <PuspenerbalModal
                isShown={isEdit}
                hide={toggleEdit}
                onCancel={toggleEdit}
                maxWidth="768px"
                headerText="Ubah Master SUCAD"
                type="edit"
                modalContent={
                  <StyledModalContent>
                    <SucadForm
                      hide={toggleEdit}
                      isEditing={true}
                      data={original}
                    />
                  </StyledModalContent>
                }
              />
              <ConfirmationModalPuspenerbal
                hide={toggleDeleteSucadTable}
                onCancel={toggleDeleteSucadTable}
                onConfirm={handleDeleteItem}
                isShowConfirm={isDeleteSucadTable}
                typeConfirmButton="button"
                type="confirm-cancel"
                headerText="Konfirmasi"
                confirmText="HAPUS"
                cancelText="BATALKAN"
                message={`Apakah anda yakin ingin menghapus data SUCAD?`}
                isDeleteConfirm
              />
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <StyledFlexResponsive style={{ marginTop: 10 }}>
      <StyledSisfoPuspenerbalBlurredWrapper style={{ padding: 24, flex: 1 }}>
        <p style={{ textTransform: "uppercase", fontSize: 14 }}>
          Modul Master Data Sucad
        </p>
        {sucadData ? (
          <BasicTable
            columns={columns}
            data={sucadData}
            noOnSearch
            noAddButton
            notSelectable
            noTableAction
            isLoading={isLoading || isFetching}
            noGeneralAction
            notNumbering
            {...tableDefaultStyle}
            customPagination={
              sucadMeta && (
                <CustomPaginationTable
                  setPage={setPage}
                  page={page}
                  setLimit={setLimit}
                  take={take}
                  meta={sucadMeta}
                  tableName="Master Data Sucad"
                />
              )
            }
          />
        ) : null}
      </StyledSisfoPuspenerbalBlurredWrapper>
    </StyledFlexResponsive>
  );
};

export default SucadTable;
