import React, {
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoMdNotifications } from "react-icons/io";
import { MdArrowDropDown } from "react-icons/md";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import { motion } from "framer-motion";
import useNotificationStyles from "./PageContainer.style";
import { COLORS } from "../assets/theme";
import { BreadCrumbObj } from "../types/breadCrumb";
import { puspenerbalBackgroundMapImage } from "../assets/images";
import { Badge } from "@mui/material";
import TimeDisplays from "./TimeDisplays";
import { useNavigate } from "react-router-dom";
import CookieMonster from "../api/CookieMonster";
import { useGetListNotificationQuery } from "store/notificationStore/notificationStoreAPI";
import { getFullDateWithTime } from "assets/usefulFunctions";
import { SisfologRoutes } from "config/nav";

interface Props {
  withControl?: boolean;
  title?: string | ReactNode;
  isFixedWidth?: boolean;
  titleRightChildren?: React.ReactNode;
  children?: React.ReactNode;
  breadCrumbItems?: BreadCrumbObj[];
}

export const PageWrapper = styled.div`
  display: flex;
  /* height: 100vh; */
`;

export const TitleRightChildrenWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* align-items: center; */
  margin-bottom: 8px;
`;

const StyledMainWrapper = styled.main`
  margin-top: 3.15rem;
`;

export const StyledSisfoPuspenerbalLoginBackgroundWrapper = styled.div`
  background-color: #000401;
  background-image: url(${puspenerbalBackgroundMapImage});
  /* min-height: 100vh; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledSisfoPuspenerbalWrapper = styled.div`
  background: rgba(219, 219, 219, 0.15);
  border: 1px solid #f2f2f2;
`;
export const StyledSisfoPuspenerbalBlurredWrapper = styled.div`
  background: ${COLORS.gradient_backgroundCard_sisfolog};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #7b7b7b;
  color: ${COLORS.white};
`;

export const StyledTitle = styled.p`
  font-size: 14px;
  text-transform: uppercase;
`;

export const SampleNotifications: Array<{
  id: number;
  message: string;
  time: string;
  isRead: boolean;
}> = [
  {
    id: 1,
    message:
      "Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel. Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel.",
    time: "12:38",
    isRead: false,
  },
  {
    id: 2,
    message:
      "Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel. Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel.",
    time: "12:38",
    isRead: false,
  },
  {
    id: 3,
    message:
      "Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel. Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel.",
    time: "12:38",
    isRead: true,
  },
];

const PageContainer: React.FunctionComponent<Props> = ({
  children,
  withControl,
  title,
  isFixedWidth,
  titleRightChildren,
  breadCrumbItems,
}) => {
  const classes = useNotificationStyles();
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const menuNotificationRef = useRef(null) as RefObject<HTMLDivElement>;
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const { data } = useGetListNotificationQuery({});
  // const user = useAppSelector((state) => state.userStore.userData);
  // const isPersonel = useUserIsPersonel();
  // const personilId = usePersonelId();
  /**
   * @todo use isLoading to mitigate empty data
   */
  // const { data: personelData } = useGetPersonelQuery(
  //   { id: personilId ? personilId : undefined },
  //   {
  //     /**
  //      * we will let personel to fetch data without supplying personilId
  //      */
  //     skip: !personilId && !isPersonel,
  //   },
  // );
  const defaultUsername = CookieMonster.loadCookie("username");
  // const username = React.useMemo(() => {
  //   if (isPersonel) {
  //     if (!personelData || !personelData[0]) return defaultUsername;
  //     const d = personelData[0];
  //     return concatPerwiraLabelInNrp(d.nrp, d.rankCategoryGeneral);
  //   }
  //   return defaultUsername;
  // }, [isPersonel, defaultUsername, personelData]);

  const onClickNotification = () => {
    setIsShowNotification((current) => {
      return !current;
    });
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuNotificationRef.current &&
        !menuNotificationRef.current.contains(event.target as null)
      ) {
        setIsShowNotification(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuNotificationRef]);

  return (
    <div
      className={classes.container}
      style={{ width: isFixedWidth ? "auto" : "100%" }}
    >
      <div className={classes.navContainer}>
        <TimeDisplays
          containerStyle={{
            flex: 1,
          }}
          breadCrumbItems={breadCrumbItems}
        />
        {withControl ? (
          <div className={classes.controlContainer} ref={menuNotificationRef}>
            {isShowNotification && (
              <motion.div>
                <div className={classes.notificationBox}>
                  <div className={classes.notificationItemBox}>
                    {data?.map((item) => {
                      return (
                        <div
                          className={[
                            classes.notificationItem,
                            // temporary disable bcs response didnt provide isRead
                            // !item.isRead ? "unread" : "",
                            "unread",
                          ].join(" ")}
                          key={item.id}
                        >
                          <div className={classes.notificationItemText}>
                            <p>{item.action}</p>
                            <div>{getFullDateWithTime(item.time)}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={classes.viewAllButton}
                    onClick={() => navigate(`${SisfologRoutes.notification}`)}
                  >
                    View All
                  </div>
                </div>
              </motion.div>
            )}
            <button
              title="notifications"
              className={classes.actionBtn}
              onClick={onClickNotification}
            >
              <Badge color="error" variant="dot">
                <IoMdNotifications
                  size={18}
                  fontSize={18}
                  color={COLORS.white}
                />
              </Badge>
            </button>
            <div>
              <Dropdown
                isOpen={showDropdown}
                toggle={() => setShowDropdown((prev) => !prev)}
              >
                <DropdownToggle
                  style={{
                    color: COLORS.white,
                    background: "none",
                    border: "none",
                    flex: 1,
                    justifyContent: "center",
                    display: "flex",
                    fontSize: 14,
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginBottom: 0, marginTop: 2 }}>
                    {defaultUsername ?? ""}
                  </p>
                  <MdArrowDropDown
                    size={24}
                    fontSize={24}
                    fill={COLORS.white}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    style={{ fontSize: 12 }}
                    onClick={() => navigate(SisfologRoutes.logout)}
                  >
                    Keluar
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* <button
              title="Logout"
              className={classes.logoutBtn}
              onClick={() => {
                logout();
              }}
            >
              <IoMdExit size={18} fontSize={18} />
            </button> */}
          </div>
        ) : (
          []
        )}
      </div>
      <StyledMainWrapper>
        {titleRightChildren ? (
          <TitleRightChildrenWrapper>
            <h1 className={classes.titleHeading}>{title}</h1>
            {titleRightChildren}
          </TitleRightChildrenWrapper>
        ) : (
          <h1 className={classes.titleHeading}>{title}</h1>
        )}
        <div className={classes.childrenContainer}>{children}</div>
      </StyledMainWrapper>
    </div>
  );
};

export default PageContainer;
