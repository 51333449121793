import { AWarehouseConditionEnum } from "types/endpoints/warehouse";
import { BasicSelectOpt } from "types/index";

export const materielKindOpts = [
  {
    label: "Fixed Wings",
    value: "FIXED_WINGS",
  },
  {
    label: "Rotary Wings",
    value: "ROTARY_WINGS",
  },
  {
    label: "UAV",
    value: "UAV",
  },
];

export const conditionOpts: BasicSelectOpt<AWarehouseConditionEnum>[] = [
  {
    label: "New",
    value: "NEW",
  },
  {
    label: "Used",
    value: "USED",
  },
];

export const satuanSucadOpts = [
  {
    label: "KG",
    value: "KG",
  },
  {
    label: "AE",
    value: "AE",
  },
  {
    label: "ASSY",
    value: "ASSY",
  },
  {
    label: "ONS",
    value: "ONS",
  },
];
