import { createTheme } from "@mui/material";
import styled from "styled-components";

import { large, medium, small, xSmall } from "assets/breakpoints";
import { COLORS, mq } from "assets/theme";

export const StyledRow = styled.div`
  gap: 16px;
  padding: 0 8px;
`;
export const StyledColumn = styled.div`
  ${mq[3]} {
    flex: 1;
  }
  padding: 0;
`;

export const StyledRightColumn = styled.div`
  padding: 0;
  @media (min-width: 1024px) {
    flex: 0 0 35%;
  }
`;

export const SearchWrapper = styled.div`
  background: ${COLORS.white};
  border-radius: 4px;
  margin-top: 12px;
  margin-right: 8px;
`;

export const StyledEmployeeListColumn = styled.div`
  background: white;
  border-radius: 4px;
  padding: 0;
  overflow-y: overlay;
  max-height: 85vh;
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 3.6px solid transparent;
  }
`;

export const StyledDropdownWrapper = styled.div`
  margin-bottom: 8px;
  gap: 16px;
  background: transparent;
`;
export const StyledDropdownContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 2px;
`;

export const StyledPersonilProfileImage = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  float: right;
  display: inline-flex;
  margin-right: 20px;
`;

export const StyledEmployeeHeaderCollapse = styled.div`
  padding: 8px;
  display: inline-flex;
  align-items: center;
  gap: 16px;
`;

export const StyledEmployeeHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
export const StyledEmployeeHeaderRightTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-right: 24px;
  text-align: right;
`;

export const accordionTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
});

export const StyledAccordionContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyleAccordionDetailWrapper = styled.div`
  padding: 8px 12px;
  border: 1px solid ${COLORS.light_grey_2};
  border-radius: 4px;
`;

export const StyledLocationImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLocationImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const OverlayImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  opacity: 0;
  background-image: linear-gradient(
    0deg,
    rgba(0, 18, 40, 0.85),
    rgba(0, 18, 40, 0.85)
  );
  border-radius: 4px;
`;

export const OverlayTextTitle = styled.div`
  position: absolute;
  top: 70%;
  left: 10%;
  text-overflow: ellipsis;
  font-size: 16px;
  white-space: normal;
  ${mq[1]} {
    top: 80%;
    left: 5%;
    font-size: 20px;
  }
  ${mq[4]} {
    top: 80%;
    left: 5%;
    font-size: 20px;
  }
  text-align: center;
  color: white;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: 600;
  opacity: 1;
`;

export const OverlayBackgroundNonImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  opacity: 1;
  background-image: linear-gradient(
    to top,
    rgba(0, 18, 40, 0.5),
    rgba(0, 18, 40, 0.12)
  );

  cursor: pointer;
  border-radius: 4px;
`;

export const StyledImageContainer = styled.div`
  position: relative;
  width: auto;
  height: 240px;
  border-radius: 4px;
  margin-bottom: 1.2rem;
  border-radius: 1rem;
  overflow: hidden;

  :hover ${OverlayImage} {
    opacity: 0.95;
  }
  :hover ${OverlayTextTitle} {
    opacity: 0;
  }
  :hover ${OverlayBackgroundNonImage} {
    opacity: 0;
  }
`;

export const OverlayText = styled.div`
  color: white;
  font-size: 14px;
  ${mq[2]} {
    font-size: 16px;
  }
  ${mq[3]} {
    font-size: 24px;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const StyledFlexResponsive = styled.div`
  display: flex;
  flex-direction: column;
  ${mq[4]} {
    flex-direction: row;
  }
  gap: 12px;
`;

export const CardPesud = styled.div<{ height?: string }>`
  background: ${COLORS.gradient_blue};
  border-radius: 0.5rem;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
  height: ${(props) => (props.height ? props.height : "300px")};
  position: relative;
  transition: all 0.2s;
  :hover {
    background: ${COLORS.gradient_blue_1};
    transform: translateY(-4px);
  }
`;

export const CardPesudText = styled.div<{ margin?: string }>`
  font-weight: 600;
  font-size: 12px;
  margin: ${(props) => (props.margin ? props.margin : " 0.5rem 0rem")};
`;

export const BackButton = styled.div`
  width: 40px;
  height: 38px;
  background: ${COLORS.white};
  border-radius: 4px;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const FlexListPersonilWrapper = styled.div`
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  ${large} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${medium} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${small} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${xSmall} {
    grid-template-columns: repeat(1, 1fr);
  }
  overflow: hidden;
  display: grid;
  gap: 16px;
`;

export const FlexPersonilPageWrapper = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

export const HorizontalLineListView = styled.div`
  border-radius: 8px;
  background-color: ${COLORS.light_grey_2};
  margin: 1rem 0rem;
  height: 0.1px;
`;

export const DetailViewWrapper = styled.div`
  margin: 0.5rem 0rem;
  height: 0.1px;
  width: 100%;
  height: 150px;
`;

export const StyledCardImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 150px;
  overflow: hidden;
  transition: all 0.2s;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledContentCollapse = styled.div`
  padding: 8px;
  display: inline-flex;
  align-items: center;
  gap: 16px;
`;
export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyleSpanDetail = styled.span`
  display: inline-block;
  min-width: 200px;
  font-size: 12px;
`;

export const StyledColDetail = styled.div`
  margin: 0.2em 0em;
`;

export const StyledRightEdit = styled.div`
  float: right;
  display: inline-flex;
`;

export const StyledFlexControl = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  flex-direction: column;
  ${mq[3]} {
    flex-direction: row;
  }
  margin-bottom: 20px;
`;

export const StyledFlexControlActivePersonel = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  flex-direction: column;
  ${mq[3]} {
    flex-direction: row;
  }
  margin-bottom: 1.25rem;
`;

export const StyledGraphBlurredWrapper = styled.div`
  background: ${COLORS.gradient_backgroundCard};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #7b7b7b;
  color: ${COLORS.white};
`;

export const DashboardCountCardGrid = styled.div<{ repeat?: string }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${mq[1]} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq[3]} {
    grid-template-columns: ${(props) =>
      props.repeat ? props.repeat : "repeat(5, 1fr)"};
  }
  grid-row-gap: 32px;
  grid-column-gap: 16px;
`;

export const DashboardCountCard = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : `${COLORS.gradient_blue_card}`};
  color: ${COLORS.white};

  text-align: center;
  h4 {
    font-weight: 400;
    text-transform: uppercase;
  }
  h2 {
    font-weight: 700;
    font-size: 48px;
    text-transform: uppercase;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: ${(props) =>
    props.borderColor
      ? `0,5px solid ${props.borderColor}`
      : "0.5px solid #7B7B7B"};
`;

export const GenerateButton = styled.button`
  padding: 1.2rem 3rem;
  white-space: nowrap;
  height: 38px;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
  background: ${COLORS.blue_1};
  font-weight: 600;
  color: #fff;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const StyledCardWrapper = styled.div`
  background: ${COLORS.gradient_backgroundCard_sisfolog};
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #7b7b7b;
  color: ${COLORS.white};
`;

export const FlexHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${small} {
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const StyledFlexResponsiveDashboard = styled.div`
  display: flex;
  flex-direction: column;
  ${mq[4]} {
    flex-direction: row;
  }
  gap: 12px;
`;

export const DetailContentStyles = styled.table`
border-collapse: separate;
text-transform: uppercase;
tbody > tr > td {
  font-size: 14px;
  ${mq[3]}: {
    font-size: 14px;
  };
  vertical-align: text-top;
  padding: 5px;
  :last-child {
    word-break: break-word;
  }
  : first-child {
    width: 80;
    ${mq[3]}: {
      width: 220;
    };
    word-break: normal;
  }
};
}`;

export const StyleGridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1em;
  ${mq[1]} {
    grid-template-columns: repeat(1fr 2fr);
  }
}`;

export const StyleLineSeparator = styled.div`
  border-bottom: 0.5px solid white;
  margin: 8px 4px;
`;

export const StyedInputColContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 2px;
`;

export const StyedDropdownContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 2px;
`;

export const Styled3ColumnsResponsive = styled.div`
  display: block;
  width: 100%;
  ${mq[4]} {
    width: calc(100% / 3);
    display: flex;
  }
`;

export const StyledBorderColumnsHorizontalResponsive = styled.div`
  border-bottom: 1px solid rgba(219, 219, 219, 0.15);
`;

export const StyledSubHeader = styled.div`
  margin: 0 -16px 10px -16px;
  padding: 12px 16px;
  font-size: 14px;
  background: ${COLORS.gradient_green_table_header};
`;
