import { useMemo, useState } from "react";

import { MdSearch } from "react-icons/md";
import { Col, Row } from "reactstrap";

import { COLORS } from "assets/theme";

import InputText from "components/InputForm/InputText";
import PageContainer from "components/PageContainer";
import {
  StyledDropdownContainer,
  StyledFlexControlActivePersonel,
} from "components/StyleComponent/StyleComponent";
import { StyledButton } from "components/Modal/utils/modal.style";
import PureInputSelectPuspenerbal from "components/molecules/PureInputSelectPuspenerbal/PureInputSelectPuspenerbal";
import {
  PuspenerbalModal,
  StyledModalContent,
} from "components/molecules/PuspenerbalModal/PuspenerbalModal";
import { useModal } from "components/Modal/utils/useModal";

import useDebounce from "hooks/tools/useDebounce";
import usePesudOpts from "hooks/masterPesudData/usePesudOptions";
import useCustomPaginationController from "hooks/table/useCustomPaginationController";

import { useGetSucadMasterQuery } from "store/masterDataSucadStore";

import { BasicSelectOpt } from "types";

import SucadTable from "./_components/SucadTable";
import SucadForm from "./_components/SucadForm";

const SucadMasterPage = () => {
  const [searchSucad, setSearchSucad] = useState<string>("");
  const [selectedPesud, setSelectedPesud] =
    useState<BasicSelectOpt<number> | null>(null);

  const { page, setPage, take, setLimit } = useCustomPaginationController({
    defaultPage: 1,
    defaultTake: 10,
  });

  const {
    page: pagePesud,
    take: takePesud,
    setLimit: setLimitPesud,
  } = useCustomPaginationController({
    defaultPage: 1,
    defaultTake: 10,
  });

  const { isShown, toggle } = useModal();
  const {
    data: dataSucad,
    isLoading,
    isFetching,
  } = useGetSucadMasterQuery({
    page: page,
    take,
    pesud: selectedPesud ? selectedPesud.value : undefined,
    search: searchSucad ?? "",
  });
  const [searchPesud, setSearchPesud] = useState<string>("");
  const debouncedSearchPesud = useDebounce(searchPesud, 1000);
  const { arr: pesudOptions, isLoading: isLoadingPesudOpts } = usePesudOpts({
    page: pagePesud,
    take: takePesud,
    kind: debouncedSearchPesud ? debouncedSearchPesud : undefined,
  });
  const handleLoadMore = () => {
    setLimitPesud((prev) => prev + 10);
  };
  const sucadMemo = useMemo(() => {
    if (!dataSucad) return [];
    const data = [...dataSucad.entities];

    return data;
  }, [dataSucad]);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <PageContainer withControl>
        <PuspenerbalModal
          isShown={isShown}
          hide={toggle}
          onCancel={toggle}
          headerText="Tambah Master SUCAD"
          maxWidth="768px"
          type="add"
          modalContent={
            <StyledModalContent>
              <SucadForm hide={toggle} isEditing={false} data={undefined} />
            </StyledModalContent>
          }
        />
        <StyledFlexControlActivePersonel>
          <Row>
            <StyledDropdownContainer as={Col}>
              <InputText
                containerInputStyle={{
                  width: 180,
                  marginBottom: 0,
                }}
                value={searchSucad}
                style={{
                  border: `1px solid ${COLORS.light_grey_3_puspenerbal}`,
                  height: 36,
                }}
                placeholder="Cari..."
                onChange={(e) => {
                  setSearchSucad(e.target.value);
                }}
                icon={<MdSearch size={14} />}
              />
            </StyledDropdownContainer>
            <StyledDropdownContainer as={Col}>
              <PureInputSelectPuspenerbal
                isClearable
                isSearchable
                options={pesudOptions}
                placeholder="Pesawat Udara"
                isLoading={isLoadingPesudOpts}
                containerStyle={{ width: 180, marginBottom: 0 }}
                controlStyle={{ height: 36 }}
                variant="dark"
                value={selectedPesud}
                onMenuScrollToBottom={handleLoadMore}
                onInputChange={(val) => setSearchPesud(val)}
                onChange={(val) => setSelectedPesud(val)}
              />
            </StyledDropdownContainer>
          </Row>
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <StyledDropdownContainer as={Col}></StyledDropdownContainer>

            <StyledDropdownContainer as={Col}>
              <StyledButton onClick={toggle}>TAMBAH MASTER SUCAD</StyledButton>
            </StyledDropdownContainer>
          </div>
        </StyledFlexControlActivePersonel>
        <SucadTable
          sucadData={sucadMemo}
          isLoading={isLoading}
          isFetching={isFetching}
          sucadMeta={dataSucad?.meta}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
          take={take}
        />
      </PageContainer>
    </div>
  );
};

export default SucadMasterPage;
