import React from "react";
import { Link } from "react-router-dom";
// import useGetSisfoParentUrl from "../hooks/useGetSisfoParentUrl";
import { useDefaultRoute } from "./Redirector";

interface Props {}

const NoMatch: React.FunctionComponent<Props> = () => {
  const defaultRoute = useDefaultRoute();
  // const sisfoParentUrl = useGetSisfoParentUrl();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <h2>Lost?</h2>
      <Link to={defaultRoute}>Get Back home</Link>
    </div>
  );
};

export default NoMatch;
