import React, { CSSProperties, forwardRef, useEffect, useRef } from "react";

interface IIndeterminateInputProps {
  indeterminate?: boolean;
  name: string;
  label?: string;
  onChange?: any;
  defaultChecked?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
}

const useCombinedRefs = (
  ...refs: Array<React.Ref<HTMLInputElement> | React.MutableRefObject<null>>
): React.MutableRefObject<HTMLInputElement | null> => {
  const targetRef = useRef(null);

  useEffect(() => {
    refs.forEach(
      (ref: React.Ref<HTMLInputElement> | React.MutableRefObject<null>) => {
        if (!ref) return;

        if (typeof ref === "function") {
          ref(targetRef.current);
        } else {
          // @ts-ignore eslint-disable-next-line
          ref.current = targetRef.current;
        }
      },
    );
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IIndeterminateInputProps
>(
  (
    { indeterminate, label, style, ...rest },
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <div
        className="d-flex align-items-center gap-3 flex-fill"
        style={{ maxWidth: "fit-content", ...style }}
      >
        <input type="checkbox" ref={combinedRef} {...rest} />
        <label className="mt-1">{label}</label>
      </div>
    );
  },
);

export default IndeterminateCheckbox;
