import { COLORS } from "../../../assets/theme";
import { BasicTableProps } from "./BasicTable";

export const tableDefaultStyle: Omit<
  BasicTableProps<any>,
  "data" | "columns"
> = {
  tableStyle: {
    marginTop: 0,
    borderCollapse: "separate",
    borderSpacing: "0 4px",
    fontSize: 12,
  },
  headerStyle: {
    border: "none",
    color: COLORS.white,
    borderRight: `1px solid ${COLORS.light_brown_1_puspenerbal}`,
    borderLeft: `1px solid ${COLORS.light_brown_1_puspenerbal}`,
    gap: 8,
    fontSize: 12,
  },
  bodyStyle: {
    background: `${COLORS.gradient_blue}`,
    // backdropFilter: "blur(5px)",
  },
  cellStyle: {
    borderRight: `1px solid ${COLORS.light_brown_1_puspenerbal}`,
    borderLeft: `1px solid ${COLORS.light_brown_1_puspenerbal}`,
  },
  tableContainerStyle: {
    minHeight: 0,
  },
};
export const tableExpandedStyle: Omit<
  BasicTableProps<any>,
  "data" | "columns"
> = {
  tableStyle: {
    marginTop: 0,
    padding: 0,
    borderCollapse: "separate",
    borderSpacing: "0 4px",
    fontSize: 12,
  },
  headerStyle: {
    border: "none",
    color: COLORS.white,
    background: "transparent",
    gap: 8,
    fontSize: 12,
  },
  bodyStyle: {
    background: "transparent",
    cursor: "default",
  },
  cellStyle: {
    background: "transparent",
  },
  tableContainerStyle: {
    minHeight: 0,
  },
};
