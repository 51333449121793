import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { AWorkUnit } from "../../types/endpoints/satker";

export const satkerStoreAPI = createApi({
  reducerPath: "satkerStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["SatkerList"],
  endpoints: (builder) => {
    return {
      getSatker: builder.query<AWorkUnit[], Partial<AWorkUnit>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/satkers" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AWorkUnit[]>) =>
          response.data,
        providesTags: ["SatkerList"],
      }),
    };
  },
});

export const {
  useGetSatkerQuery,
  util: { resetApiState: resetSatkerStoreAPI },
} = satkerStoreAPI;
