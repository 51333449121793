import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  AMasterDataRFID,
  ASubmitMasterDataRFID,
} from "types/endpoints/masterData";

export const masterRFIDStoreAPI = createApi({
  reducerPath: "masterRFIDStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/master`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MasterDataRFIDList"],
  endpoints: (builder) => {
    return {
      getMasterRFID: builder.query<AMasterDataRFID[], Partial<AMasterDataRFID>>(
        {
          query: ({ id, ...rest }) => {
            let params = "";
            if (rest) {
              params = convertToEncodedURL(rest);
              params = params.length ? "?" + params : "";
            }
            let url = `${id}/rfid` + params;
            return {
              url,
              method: "GET",
            };
          },
          providesTags: ["MasterDataRFIDList"],
          transformResponse: (response: BackendDataShape<AMasterDataRFID[]>) =>
            response.data,
        },
      ),
      createMasterRFID: builder.mutation<
        BackendDataShape<object>,
        ASubmitMasterDataRFID
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}/rfid`,
            body: rest,
            method: "POST",
          };
        },
        invalidatesTags: ["MasterDataRFIDList"],
      }),
      updateMasterRFID: builder.mutation<
        BackendDataShape<object>,
        ASubmitMasterDataRFID
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}/rfid`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MasterDataRFIDList"],
      }),
      deleteMasterRFID: builder.mutation<
        BackendDataShape<object>,
        Pick<ASubmitMasterDataRFID, "id">
      >({
        query: ({ id }) => {
          return {
            url: `/${id}/rfid`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["MasterDataRFIDList"],
      }),
    };
  },
});

export const {
  useCreateMasterRFIDMutation,
  useDeleteMasterRFIDMutation,
  useUpdateMasterRFIDMutation,
  useGetMasterRFIDQuery,
  util: { resetApiState: resetMasterRFIDStoreAPI },
} = masterRFIDStoreAPI;
