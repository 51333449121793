import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";
import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  AMasterData,
  AMasterDataFile,
  AMasterDataStockObj,
} from "types/endpoints/masterData";

export const masterDataStoreAPI = createApi({
  reducerPath: "masterDataStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/masters`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "MasterDataList",
    "MasterDataDistributionList",
    "MasterDataChecklist",
    "MasterDataFileList",
    "MasterDataDistributionList",
  ],
  endpoints: (builder) => {
    return {
      getMasterData: builder.query<AMasterData[], Partial<AMasterData>>({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `` + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["MasterDataList"],
        transformResponse: (response: BackendDataShape<AMasterData[]>) =>
          response.data,
      }),
      getMasterDataFileList: builder.query<AMasterDataFile[], { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}/files`,
            method: "GET",
          };
        },
        providesTags: ["MasterDataFileList"],
        transformResponse: (response: BackendDataShape<AMasterDataFile[]>) => {
          return response.data;
        },
      }),
      createMasterData: builder.mutation<
        BackendDataShape<object>,
        Omit<Partial<AMasterData>, "id">
      >({
        query: (data) => {
          return {
            url: ``,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["MasterDataList"],
      }),
      updateMasterData: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterData>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MasterDataList"],
      }),
      deleteMasterData: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["MasterDataList"],
      }),
      uploadMasterDataFile: builder.mutation<
        BackendDataShape<object> & { statusCode: number },
        { id: number; file: File }
      >({
        query: ({ id, file }) => {
          const formData = new FormData();
          formData.append(`file`, file as any);
          return {
            url: `/${id}/files`,
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["MasterDataFileList"],
      }),
      updateMasterDataStock: builder.mutation<
        BackendDataShape<AMasterData>,
        Partial<AMasterDataStockObj>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}/stock`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MasterDataList"],
      }),
    };
  },
});

export const {
  useCreateMasterDataMutation,
  useDeleteMasterDataMutation,
  useGetMasterDataQuery,
  useUpdateMasterDataMutation,
  useUploadMasterDataFileMutation,
  useGetMasterDataFileListQuery,
  useUpdateMasterDataStockMutation,
  util: { resetApiState: resetMasterDataStoreAPI },
} = masterDataStoreAPI;
