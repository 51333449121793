import { CSSProperties } from "react";
import Select, {
  Props as SelectProps,
  components,
  GroupBase,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { Label } from "reactstrap";

import { useTableActionStyles } from "assets/hooks/table/useTableActionStyles";
import { COLORS } from "assets/theme";
import { backgroundVariantColorSwitch } from "assets/usefulFunctions";

import {
  InputVariant,
  useInputTextStyle,
} from "components/InputForm/InputText";

function PureInputSelectPuspenerbal<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  noOptionsMessage,
  styles,
  placeholder,
  variant,
  label,
  isError,
  hideBorderOption,
  hideIndicatorSeparator,
  containerStyle,
  controlStyle,
  required,
  portalTarget = document.body,
  isCreateOption,
  errorText,
  ...rest
}: SelectProps<Option, IsMulti, Group> & {
  variant?: InputVariant;
  label?: string;
  required?: boolean;
  isError?: boolean;
  hideBorderOption?: boolean;
  hideIndicatorSeparator?: boolean;
  containerStyle?: CSSProperties;
  controlStyle?: CSSProperties;
  isCreateOption?: boolean;
  portalTarget?: HTMLElement;
  errorText?: string;
}) {
  const classesAction = useTableActionStyles();
  const inputTextClasses = useInputTextStyle({ theme: { variant } });
  return (
    <>
      <div style={{ marginBottom: 16, ...containerStyle }}>
        {label && (
          <Label
            className={[
              isError ? inputTextClasses.errorText : inputTextClasses.labelText,
            ].join(" ")}
          >
            {label}
          </Label>
        )}
        {required ? <span className={inputTextClasses.errorText}>*</span> : ""}
        {isCreateOption ? (
          <CreatableSelect
            placeholder={placeholder ? placeholder : "Select..."}
            components={{
              DropdownIndicator: components.DropdownIndicator,
              IndicatorSeparator: hideIndicatorSeparator
                ? () => null
                : components.IndicatorSeparator,
            }}
            noOptionsMessage={
              noOptionsMessage
                ? noOptionsMessage
                : ({ inputValue }) => {
                    if (inputValue) return `'${inputValue}' not found!`;
                    return "No Option!";
                  }
            }
            styles={{
              control: (curStyle, { isDisabled }) => {
                return {
                  ...curStyle,
                  minHeight: 36,
                  cursor: isDisabled ? "not-allowed" : "unset",
                  background: isError
                    ? COLORS.gradient_red_error_puspenerbal
                    : backgroundVariantColorSwitch(variant, isDisabled),
                  borderRadius: 0,
                  border: isError
                    ? `1px solid ${COLORS.red_1_puspenerbal}`
                    : hideBorderOption
                    ? "none"
                    : variant === "dark"
                    ? `1px solid ${COLORS.light_grey_3_puspenerbal}`
                    : "none",
                  backdropFilter: "blur(5px)",
                  boxShadow: "none",
                  color: "white",
                  ...controlStyle,
                };
              },
              placeholder: (styles) => ({
                ...styles,
                color: COLORS.dark_grey_1,
                fontSize: "1.4em",
                whiteSpace: "nowrap",
              }),
              input: (curStyle) => ({
                ...curStyle,
                color: "#FFFFFF",
                fontSize: "1.4em",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#FFFFFF",
                fontSize: "1.4em",
              }),
              multiValue: (styles) => {
                return {
                  ...styles,
                  backgroundColor: "#18A0FB",
                  borderRadius: "16px",
                  padding: "2px 8px",
                  color: "white",
                  margin: 0,
                };
              },
              multiValueLabel: (styles) => ({
                ...styles,
                color: "#FFFFFF",
                fontSize: "1.4em",
              }),
              option: (styles, { isDisabled, isFocused }) => {
                return {
                  ...styles,
                  color: isFocused ? "#fff" : "#fff",
                  background: isError
                    ? COLORS.gradient_red_error_puspenerbal
                    : backgroundVariantColorSwitch(variant, isDisabled),
                  fontSize: "1.4em",
                };
              },
              dropdownIndicator: (styles) => ({
                ...styles,
                padding: 0,
                marginLeft: "5px",
                marginRight: "5px",
              }),
              noOptionsMessage: (styles) => ({
                ...styles,
                color: "red",
              }),
              menu: (styles) => ({
                ...styles,
                borderRadius: 0,
                marginTop: "5px",
                zIndex: 99999,
                backgroundColor:
                  variant === "light"
                    ? COLORS.light_brown_1_puspenerbal
                    : COLORS.black_1_puspenerbal,
              }),
              menuList: (styles) => ({
                ...styles,
                padding: 0,
                backgroundColor:
                  variant === "light"
                    ? COLORS.light_brown_1_puspenerbal
                    : COLORS.black_1_puspenerbal,
              }),
              container: (styles) => ({
                ...styles,
                width: "fill-available",
              }),
              ...styles,
            }}
            {...rest}
          />
        ) : (
          <Select
            menuPortalTarget={portalTarget}
            menuPosition="fixed"
            placeholder={placeholder ? placeholder : "Select..."}
            components={{
              DropdownIndicator: components.DropdownIndicator,
              IndicatorSeparator: hideIndicatorSeparator
                ? () => null
                : components.IndicatorSeparator,
            }}
            noOptionsMessage={
              noOptionsMessage
                ? noOptionsMessage
                : ({ inputValue }) => {
                    if (inputValue) return `'${inputValue}' not found!`;
                    return "No Option!";
                  }
            }
            styles={{
              control: (curStyle, { isDisabled }) => {
                return {
                  ...curStyle,
                  minHeight: 36,
                  cursor: isDisabled ? "not-allowed" : "unset",
                  background: isError
                    ? COLORS.gradient_red_error_puspenerbal
                    : backgroundVariantColorSwitch(variant, isDisabled),
                  borderRadius: 0,
                  border: isError
                    ? `1px solid ${COLORS.red_1_puspenerbal}`
                    : hideBorderOption
                    ? "none"
                    : variant === "dark"
                    ? `1px solid ${COLORS.light_grey_3_puspenerbal}`
                    : "none",
                  backdropFilter: "blur(5px)",
                  boxShadow: "none",
                  color: "white",
                  ...controlStyle,
                };
              },
              placeholder: (styles) => ({
                ...styles,
                color: COLORS.dark_grey_1,
                fontSize: "1.4em",
                whiteSpace: "nowrap",
              }),
              input: (curStyle) => ({
                ...curStyle,
                color: "#FFFFFF",
                fontSize: "1.4em",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#FFFFFF",
                fontSize: "1.2em",
              }),
              multiValue: (styles) => {
                return {
                  ...styles,
                  backgroundColor: "#18A0FB",
                  borderRadius: "16px",
                  padding: "2px 8px",
                  color: "white",
                  margin: 0,
                };
              },
              multiValueLabel: (styles) => ({
                ...styles,
                color: "#FFFFFF",
                fontSize: "1.2em",
              }),
              option: (styles, { isDisabled, isFocused }) => {
                return {
                  ...styles,
                  color: isFocused ? "#fff" : "#fff",
                  background: isError
                    ? COLORS.gradient_red_error_puspenerbal
                    : backgroundVariantColorSwitch(variant, isDisabled),
                  fontSize: "1.4em",
                };
              },
              dropdownIndicator: (styles) => ({
                ...styles,
                padding: 0,
                marginLeft: "5px",
                marginRight: "5px",
              }),
              noOptionsMessage: (styles) => ({
                ...styles,
                color: "red",
              }),
              menu: (styles) => ({
                ...styles,
                borderRadius: 0,
                marginTop: "5px",
                zIndex: 99999,
                backgroundColor:
                  variant === "light"
                    ? COLORS.light_brown_1_puspenerbal
                    : COLORS.black_1_puspenerbal,
              }),
              menuList: (styles) => ({
                ...styles,
                padding: 0,
                backgroundColor:
                  variant === "light"
                    ? COLORS.light_brown_1_puspenerbal
                    : COLORS.black_1_puspenerbal,
              }),
              container: (styles) => ({
                ...styles,
                width: "fill-available",
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              ...styles,
            }}
            {...rest}
          />
        )}
        {(rest["aria-errormessage"] || errorText) && (
          <p className={classesAction.requiredText}>
            {rest["aria-errormessage"] || errorText}
          </p>
        )}
      </div>
    </>
  );
}

export default PureInputSelectPuspenerbal;
