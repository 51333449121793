import { FC, useCallback, useState, useEffect } from "react";
import { Col, Row, Form } from "reactstrap";
import { toast } from "react-toastify";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Footer,
  CancelButton,
  StyledButton,
  StyledSubmitFormButtonWrapper,
} from "components/Modal/utils/modal.style";
import InputText from "components/InputForm/InputText";
import PureInputSelectPuspenerbal from "components/molecules/PureInputSelectPuspenerbal/PureInputSelectPuspenerbal";
import { ConfirmationModalPuspenerbal } from "components/Modal/ConfirmationModalPuspenerbal";
import { useModal } from "components/Modal/utils/useModal";
import StyledToastBodyPuspenerbal from "components/Toast/StyledToastBodyPuspenerbal";
import { PuspenerbalModal } from "components/molecules/PuspenerbalModal/PuspenerbalModal";
import QrReader from "components/QRReader";

import usePesudOpts from "hooks/masterPesudData/usePesudOptions";
import useSucadOpts from "hooks/masterSucadData/useSucadOptions";
import useCustomPaginationController from "hooks/table/useCustomPaginationController";
import useDebounce from "hooks/tools/useDebounce";

import { ISucadMasterRequest } from "types/endpoints/masterSucadData";
import {
  IMasterSucad,
  IMasterDataSucadRequestObject,
} from "types/endpoints/sucad";

import { satuanSucadOpts } from "utils/constants";

import { masterDataSucadValidationSchema } from "config/validationSchemas/masterDataSucadValidationSchema";

import {
  useCreateSucadMasterMutation,
  useUpdateSucadMasterMutation,
} from "store/masterDataSucadStore";

import { COLORS } from "assets/theme";
import { qrCode_svg } from "assets/icons/svg";
import { BaseIcon } from "assets/icons";

interface Props {
  hide: () => void;
  isEditing?: boolean;
  data?: IMasterSucad | undefined;
}

export const SucadForm: FC<Props> = ({ data: dataSucad, hide, isEditing }) => {
  const [scannedResult, setScannedResult] = useState<string>("");
  const [searchPesud, setSearchPesud] = useState<string>("");
  const debouncedSearchPesud = useDebounce(searchPesud, 1000);
  const { page, take, setLimit } = useCustomPaginationController({
    defaultPage: 1,
    defaultTake: 10,
  });
  const { arr: pesudOptions, isLoading: isLoadingPesudOpts } = usePesudOpts({
    page,
    take,
    kind: debouncedSearchPesud ? debouncedSearchPesud : undefined,
  });

  const { arr: sucadOptions, isLoading: isLoadingSucadOpts } = useSucadOpts({
    page,
    take,
  });

  const { toggle: toggleConfirm, isShown: isShowConfirm } = useModal();
  const { toggle: toggleConfirmCancel, isShown: isShowConfirmCancel } =
    useModal();

  const { isShown: isShowQR, toggle: toggleShowQR } = useModal();
  const { toggle: toggleEditConfirm, isShown: isShowEditConfirm } = useModal();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [createSucadMaster] = useCreateSucadMasterMutation();
  const [updateSucadMaster] = useUpdateSucadMasterMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<IMasterDataSucadRequestObject>({
    mode: "onChange",
    resolver: yupResolver<IMasterDataSucadRequestObject>(
      masterDataSucadValidationSchema,
    ),
  });

  const handleLoadMore = () => {
    setLimit((prev) => prev + 10);
  };

  const handleSubmitItem: SubmitHandler<IMasterDataSucadRequestObject> =
    useCallback(
      (data) => {
        setIsSubmitting(true);

        const obj: Partial<ISucadMasterRequest> = {
          partNumber: data.partNumber,
          name: data.name,
          pesudId: data.pesudId.value,
          alternativePartIds: data.alternativePartId
            ? data.alternativePartId.map((value) => value.value)
            : [],
          unit: data.unit.value,
        };

        if (isEditing) {
          updateSucadMaster({ id: dataSucad?.id, ...obj })
            .unwrap()
            .then(() => {
              setIsSubmitting(false);
              hide();
              toggleConfirm();
              toast.success(
                <StyledToastBodyPuspenerbal
                  headerText="Berhasil!"
                  description={`Anda berhasil mengubah data Master SUCAD.`}
                />,
              );
            })
            .catch((e) => {
              setIsSubmitting(false);
              toggleConfirm();
              toast.error(
                <StyledToastBodyPuspenerbal
                  headerText="Gagal"
                  description={
                    <div>
                      Anda gagal mengubah data Master SUCAD.
                      {e.data.message}
                    </div>
                  }
                />,
              );
            });
        } else {
          createSucadMaster(obj)
            .unwrap()
            .then(() => {
              setIsSubmitting(false);
              hide();
              toggleConfirm();
              toast.success(
                <StyledToastBodyPuspenerbal
                  headerText="Berhasil!"
                  description={`Anda berhasil menambahkan data Master SUCAD.`}
                />,
              );
            })
            .catch((e) => {
              setIsSubmitting(false);
              toggleConfirm();
              toast.error(
                <StyledToastBodyPuspenerbal
                  headerText="Gagal"
                  description={
                    <div>
                      Anda gagal menambahkan data Master SUCAD.
                      {e.data.message}
                    </div>
                  }
                />,
              );
            });
        }
      },
      [
        createSucadMaster,
        dataSucad?.id,
        isEditing,
        updateSucadMaster,
        hide,
        toggleConfirm,
      ],
    );
  const defaultValuePartNumber = dataSucad?.alternativePart
    ? dataSucad.alternativePart.map((value) => ({
        label: value.partNumber,
        value: value.id,
      }))
    : undefined;

  useEffect(() => {
    if (scannedResult) {
      setValue("partNumber", scannedResult);
      toggleShowQR();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedResult, setValue]);

  return (
    <>
      <Form id="SucadForm" onSubmit={handleSubmit(handleSubmitItem)}>
        <ConfirmationModalPuspenerbal
          hide={toggleConfirm}
          onCancel={toggleConfirm}
          isSubmitOnProgress={isSubmitting}
          isShowConfirm={isShowConfirm}
          idForm="SucadForm"
          typeConfirmButton="submit"
          type="confirm-add"
          headerText="Konfirmasi"
          confirmText="Ya, lanjutkan"
          cancelText="Cek Kembali"
          message="Apakah anda yakin data yang anda inputkan sudah benar?"
          isDeleteConfirm={false}
        />
        <ConfirmationModalPuspenerbal
          hide={toggleConfirmCancel}
          onCancel={toggleConfirmCancel}
          onConfirm={() => {
            toggleConfirmCancel();
            hide();
          }}
          isShowConfirm={isShowConfirmCancel}
          typeConfirmButton="button"
          type="confirm-cancel-add"
          headerText="Konfirmasi"
          confirmText="BATALKAN"
          cancelText="Kembali"
          message={`Apakah anda yakin ingin membatalkan ${
            isEditing ? "perubahan" : "penambahan"
          } data Master SUCAD? Data yang telah diisi akan hilang jika melanjutkan pembatalan.`}
          isDeleteConfirm={false}
        />

        <ConfirmationModalPuspenerbal
          hide={toggleEditConfirm}
          onCancel={toggleEditConfirm}
          isSubmitOnProgress={isSubmitting}
          isShowConfirm={isShowEditConfirm}
          idForm="SucadForm"
          typeConfirmButton="submit"
          type="confirm-add"
          headerText="Konfirmasi"
          confirmText="Ya, Lanjutkan"
          cancelText="Batalkan"
          message={`Apakah anda yakin data yang anda inputkan sudah benar?`}
          isDeleteConfirm={false}
        />
        <PuspenerbalModal
          isShown={isShowQR}
          hide={toggleShowQR}
          onCancel={toggleShowQR}
          headerText={"QR Scanner"}
          modalContent={<QrReader setScannedResult={setScannedResult} />}
          type="bind"
        />
        <Row>
          <Col xs={6} style={{ position: "relative" }}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  label="PART NUMBER"
                  value={value}
                  onChange={onChange}
                  isError={!!error}
                  errorText={error && error.message}
                  placeholder="Part Number"
                  required
                  style={{ width: "90%" }}
                />
              )}
              defaultValue={dataSucad?.partNumber ?? ""}
              name="partNumber"
              control={control}
            />
            <BaseIcon
              src={qrCode_svg}
              fontSize={"1.2rem"}
              height="20"
              width="20"
              style={{
                marginRight: 16,
                position: "absolute",
                zIndex: 2,
                bottom: "23px",
                right: 0,
              }}
              strokeWidth={0}
              viewBox="0 0 16 16"
              cursor={"pointer"}
              color={COLORS.white}
              onClick={() => toggleShowQR()}
            />
          </Col>
          <Col xs={6}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  label="NAMA BARANG"
                  value={value}
                  onChange={onChange}
                  placeholder="Nama Barang"
                  isError={!!error}
                  errorText={error && error.message}
                  required
                />
              )}
              name="name"
              defaultValue={dataSucad?.name ?? ""}
              control={control}
            />
          </Col>
          <Col xs={12} style={{ marginBottom: 15 }}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PureInputSelectPuspenerbal
                  isClearable
                  isSearchable
                  onMenuScrollToBottom={handleLoadMore}
                  label="PESUD"
                  errorText={error && error.message}
                  options={pesudOptions}
                  placeholder="Pilih Pesud"
                  onInputChange={(val) => setSearchPesud(val)}
                  containerStyle={{ marginBottom: 2, minWidth: 180 }}
                  controlStyle={{ height: 36 }}
                  variant="dark"
                  isLoading={isLoadingPesudOpts}
                  value={value}
                  onChange={onChange}
                  required
                  portalTarget={document.body}
                />
              )}
              name="pesudId"
              defaultValue={
                dataSucad?.pesud
                  ? {
                      label: dataSucad?.pesud.kind ?? "",
                      value: dataSucad?.pesud.id ?? 0,
                    }
                  : undefined
              }
              control={control}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  label="KELAS"
                  value={value}
                  onChange={onChange}
                  isError={!!error}
                  errorText={error && error.message}
                  placeholder="Kelas Pesud"
                  disabled
                />
              )}
              name="kelas"
              defaultValue={dataSucad?.pesud?.category ?? undefined}
              control={control}
            />
          </Col>
          <Col xs={6}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  label="JENIS PESUD"
                  value={value}
                  errorText={error && error.message}
                  onChange={onChange}
                  placeholder="Jenis Pesud"
                  disabled
                />
              )}
              name="kind"
              control={control}
              defaultValue={dataSucad?.pesud?.kind ?? undefined}
            />
          </Col>
          <Col xs={6}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PureInputSelectPuspenerbal
                  isClearable
                  isMulti
                  isSearchable
                  label="ALTERNATIVE PART NUMBER"
                  options={sucadOptions}
                  errorText={error && error.message}
                  isLoading={isLoadingSucadOpts}
                  onMenuScrollToBottom={handleLoadMore}
                  placeholder="Pilih Alternative Part Number"
                  containerStyle={{ marginBottom: 2, minWidth: 180 }}
                  controlStyle={{ minHeight: 36 }}
                  variant="dark"
                  value={value}
                  onChange={onChange}
                  portalTarget={document.body}
                />
              )}
              name="alternativePartId"
              defaultValue={defaultValuePartNumber ?? undefined}
              control={control}
            />
          </Col>
          <Col xs={6}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PureInputSelectPuspenerbal
                  isClearable
                  isSearchable
                  label="SATUAN"
                  options={satuanSucadOpts}
                  placeholder="Pilih Satuan"
                  containerStyle={{ marginBottom: 2, minWidth: 180 }}
                  controlStyle={{ height: 36 }}
                  variant="dark"
                  value={value}
                  onChange={onChange}
                  required
                  errorText={error && error.message}
                  portalTarget={document.body}
                />
              )}
              name="unit"
              defaultValue={
                dataSucad?.satuan
                  ? {
                      label: dataSucad?.satuan.name ?? "",
                      value: dataSucad?.satuan.name ?? "",
                    }
                  : undefined
              }
              control={control}
            />
          </Col>
        </Row>

        <Footer>
          <StyledSubmitFormButtonWrapper>
            <CancelButton type="button" onClick={toggleConfirmCancel}>
              BATAL
            </CancelButton>
            <StyledButton
              type="button"
              disabled={!isValid || !isDirty}
              onClick={isEditing ? toggleEditConfirm : toggleConfirm}
            >
              {isEditing ? "UBAH" : "TAMBAH"}
            </StyledButton>
          </StyledSubmitFormButtonWrapper>
        </Footer>
      </Form>
    </>
  );
};

export default SucadForm;
