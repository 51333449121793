import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import {
  convertToEncodedURL,
  jsonToFormData,
} from "../../assets/usefulFunctions";
import {
  AMaterial,
  AMaterialDetail,
} from "../../types/endpoints/material/material";

export const detailMaterialStoreAPI = createApi({
  reducerPath: "detailMaterialStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MaterialDetailList"],
  endpoints: (builder) => {
    return {
      getDetailMaterial: builder.query<AMaterialDetail, Pick<AMaterial, "id">>({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `/asset/${id}/asset_detail` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AMaterialDetail>) =>
          response.data,
        providesTags: (_, __, c) => [
          "MaterialDetailList",
          { id: c.id, type: "MaterialDetailList" },
        ],
      }),

      createDetailMaterial: builder.mutation<
        BackendDataShape<object>,
        AMaterialDetail
      >({
        query: ({ id, ...rest }) => {
          const data = jsonToFormData(rest);
          return {
            url: `/asset/${id}/asset_detail`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["MaterialDetailList"],
      }),

      updateDetailMaterial: builder.mutation<
        BackendDataShape<object>,
        AMaterialDetail
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/asset/${id}/asset_detail`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MaterialDetailList"],
      }),

      deleteDetailMaterial: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/asset/${id}/asset_detail`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["MaterialDetailList"],
      }),
    };
  },
});

export const {
  useCreateDetailMaterialMutation,
  useGetDetailMaterialQuery,
  useDeleteDetailMaterialMutation,
  useUpdateDetailMaterialMutation,
  util: { resetApiState: resetDetailMaterialStoreAPI },
} = detailMaterialStoreAPI;
