import { lazy, Suspense } from "react";
import {
  MdDashboard,
  MdOutlineAccountCircle,
  MdOutlineLogout,
  MdViewList,
  MdDocumentScanner,
  MdOutlineBackup,
  MdOutlineDescription,
  MdWarning,
} from "react-icons/md";
import { HashRouter, Route, Routes, Outlet } from "react-router-dom";

import LoaderPage from "components/atoms/Loader/LoaderPage/LoaderPage";
import AuthHOC from "components/auth/AuthHOC";
import { SidebarItemProps } from "components/Sidebar/SidebarItem";
import { StyledToastContainerPuspenerbal } from "components/Toast/StyledToastContainerPuspenerbal";

import NoMatch from "pages/NoMatch";
import Redirector from "pages/Redirector";

import { COLORS } from "assets/theme";
import Layout from "layout/Layout";
import { ListOfRoutes, SisfologRoutes } from "config/nav";
import UserGate from "components/auth/UserGate";
import SucadMasterPage from "pages/masters/SucadPage";

const LoginPage = lazy(() => import("pages/LoginPage"));
const SisfoPersLogoutPage = lazy(() => import("pages/Logout"));

/**
 * @deprecated
 */
//@ts-ignore
// const MaterialManagementPage = lazy(
//   () => import("pages/Management/masters/materiel/MaterielPage"),
// );

const MaterialComponentManagementPage = lazy(
  () =>
    import("pages/Management/masters/materiel-component/MaterialComponentPage"),
);
// const SparepartManagementPage = lazy(
//   () => import("pages/Management/masters/sparepart/SparepartPage"),
// );
const WarehouseItemsManagementPage = lazy(
  () => import("pages/Management/warehouse/WarehouseItemsPage"),
);
const ReportPage = lazy(() => import("pages/Report"));

// const MaterialViewPage = lazy(
//   () => import("pages/Management/MaterialViewPage"),
// );
const MasterDataChecklistPage = lazy(
  () => import("pages/Management/masters/checklist/MasterDataChecklistPage"),
);
const WarehouseItemsPICPage = lazy(
  () => import("pages/Management/warehouse/warehouse-pic/WarehousePICPage"),
);

const MaterialEditFormPage = lazy(
  () => import("pages/Management/Form/MaterialEditFormPage"),
);
const OverviewDashboardPage = lazy(() => import("pages/DashboardPage"));

const MaintenanceSchedulePage = lazy(
  () => import("pages/Management/MaintenancePage"),
);

const ManagementUserPage = lazy(
  () => import("pages/Superadmin/ManagementUserPage"),
);
const ManagementWorkflowPage = lazy(
  () => import("pages/Superadmin/WorkflowPage"),
);

const WorkflowWarehousePage = lazy(
  () => import("pages/Management/WorkflowWarehousePage"),
);

const FileManagementPage = lazy(() => import("pages/Management/FilePage"));

const ChangePwdPage = lazy(() => import("pages/AccountPage/ChangePwdPage"));
const GetOtpPage = lazy(() => import("pages/AccountPage/GetOtpPage"));

const IntegrasiRFIDPage = lazy(() => import("pages/IntegrasiRFIDPage"));

const TransactionPage = lazy(
  () => import("pages/Management/TransactionPage/Sucad"),
);

const NotificationPage = lazy(() => import("pages/NotificationPage"));

const DistributionPage = lazy(
  () => import("pages/Management/DistributionPage"),
);

const PesudMasterPage = lazy(() => import("pages/masters/PesudPage"));

const PesudLongFormPage = lazy(
  () => import("pages/masters/PesudPage/PesudLongFormPage"),
);

const PesudDetailPage = lazy(
  () => import("pages/masters/PesudPage/PesudDetailPage"),
);

const PesudManagementPage = lazy(() => import("pages/Management/PesudPage"));
const SucadManagementPage = lazy(() => import("pages/Management/SucadPage"));

const PesudManagementDetailPage = lazy(
  () => import("pages/Management/PesudPage/PesudDetailPage"),
);

const AlertPage = lazy(() => import("pages/AlertPage"));

const BackupRestorePage = lazy(() => import("pages/BackupRestore"));

const basicSisfopersNav: SidebarItemProps[] = [
  {
    Icon: () => (
      <MdDashboard size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfologRoutes.dashboard_overview,
    title: "Dashboard",
    allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
  },
  {
    Icon: () => (
      <MdWarning size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfologRoutes.alert,
    title: "Peringatan",
    allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
  },
  {
    Icon: () => (
      <MdOutlineBackup size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfologRoutes.master,
    title: "Master",
    kiddos: [
      {
        title: "Data Pesud",
        shrinkTitle: "DP",
        kiddoURL: SisfologRoutes.pesudMaster,
      },
      {
        title: "Data Sucad",
        shrinkTitle: "DS",
        kiddoURL: SisfologRoutes.sucadMaster,
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
  },
  {
    Icon: () => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfologRoutes.management,
    title: "Manajemen",
    kiddos: [
      {
        title: "Akun",
        shrinkTitle: "AK",
        allowedUsers: ["superadmin"],
        kiddoURL: SisfologRoutes.management_account,
      },
      {
        title: "Alur Kerja",
        shrinkTitle: "AKR",
        allowedUsers: ["superadmin"],
        kiddoURL: SisfologRoutes.management_workflow,
      },
      {
        title: "Data Pesud",
        shrinkTitle: "MP",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.pesudManagement,
      },
      {
        title: "Data Sucad",
        shrinkTitle: "MS",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.sucadManagement,
      },
      // {
      //   title: "Materiel",
      //   shrinkTitle: "MA",
      //      allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
      //   kiddoURL: SisfologRoutes.materialManagement,
      // },
      {
        title: "Komponen",
        shrinkTitle: "MK",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.materialComponentManagement,
      },
      // {
      //   title: "Suku Cadang",
      //   shrinkTitle: "SC",
      //   allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
      //   kiddoURL: SisfologRoutes.sparePartManagement,
      // },
      {
        title: "Pergudangan",
        shrinkTitle: "WH",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.warehouseManagement,
      },
      {
        title: "Pemeliharaan",
        shrinkTitle: "P",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.maintenanceManagement,
      },
      {
        title: "Penanggung Jawab Pergudangan",
        shrinkTitle: "PJP",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.warehousePicManagement,
      },
      {
        title: "Perawatan",
        shrinkTitle: "PR",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.checklistManagement,
      },
      {
        title: "File",
        shrinkTitle: "FL",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.fileManagement,
      },
      {
        title: "Distribusi",
        shrinkTitle: "DS",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.distributionManagement,
      },
      {
        title: "Transaksi",
        shrinkTitle: "TS",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.transactionManagement,
      },
      {
        title: "Integrasi RFID",
        Icon: () => (
          <MdDocumentScanner
            size={18}
            color={COLORS.white}
            fill={COLORS.white}
          />
        ),
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.integrasiRFID,
      },
      {
        title: "Alur Kerja Pergudangan",
        shrinkTitle: "AKP",
        allowedUsers: ["kasi", "kasubsi", "kasubdit", "user", "personel"],
        kiddoURL: SisfologRoutes.management_warehouse_workflow,
      },
    ],
    allowedUsers: [
      "kasi",
      "kasubsi",
      "kasubdit",
      "superadmin",
      "personel",
      "user",
    ],
  },

  {
    Icon: () => (
      <MdOutlineBackup size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfologRoutes.backup_restore,
    title: "Pencadangan",
    kiddos: [
      {
        title: "Backup",
        shrinkTitle: "BK",
        kiddoURL: SisfologRoutes.backup,
        allowedUsers: ["superadmin"],
      },
      {
        title: "Restore",
        shrinkTitle: "RS",
        kiddoURL: SisfologRoutes.restore,
        allowedUsers: ["superadmin"],
      },
    ],
    allowedUsers: ["superadmin"],
  },
  {
    Icon: () => (
      <MdOutlineDescription
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: SisfologRoutes.report,
    title: "Laporan",
  },
  {
    Icon: () => (
      <MdOutlineAccountCircle
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: SisfologRoutes.account,
    title: "Akun",
    kiddos: [
      {
        title: "Ubah Password",
        shrinkTitle: "UP",
        kiddoURL: SisfologRoutes.account_changePwd,
      },
      {
        title: "GENERATE OTP",
        shrinkTitle: "GO",
        kiddoURL: SisfologRoutes.account_getOTP,
      },
    ],
  },
  {
    Icon: () => (
      <MdOutlineLogout size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfologRoutes.logout,
    title: "Keluar",
  },
];

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route
              path={SisfologRoutes.home}
              element={
                <Layout sidebarItems={basicSisfopersNav}>
                  <AuthHOC>
                    <Outlet />
                  </AuthHOC>
                </Layout>
              }
            >
              <Route index element={<Redirector />} />
              <Route
                path={SisfologRoutes.logout}
                element={<SisfoPersLogoutPage />}
              />
              <Route path={`${SisfologRoutes.alert}`} element={<AlertPage />} />
              <Route
                path={`${SisfologRoutes.dashboard_overview}`}
                element={<OverviewDashboardPage />}
              />
              <Route
                path={SisfologRoutes.account_changePwd}
                element={<ChangePwdPage />}
              />
              <Route
                path={SisfologRoutes.account_getOTP}
                element={<GetOtpPage />}
              />
              <Route
                path={`${SisfologRoutes.integrasiRFID}`}
                element={<IntegrasiRFIDPage />}
              />
              <Route
                path={`${SisfologRoutes.distributionManagement}`}
                element={<DistributionPage />}
              />
              {/* <Route
                path={`${SisfologRoutes.materialManagement}`}
                element={<MaterialManagementPage />}
              /> */}
              <Route
                path={`${SisfologRoutes.materialComponentManagement}`}
                element={<MaterialComponentManagementPage />}
              />
              {/* <Route
                path={`${SisfologRoutes.sparePartManagement}`}
                element={<SparepartManagementPage />}
              /> */}
              <Route
                path={`${SisfologRoutes.warehouseManagement}`}
                element={<WarehouseItemsManagementPage />}
              />
              <Route
                path={`${SisfologRoutes.sucadManagement}`}
                element={<SucadManagementPage />}
              />
              <Route
                path={`${SisfologRoutes.maintenanceManagement}`}
                element={<MaintenanceSchedulePage />}
              />
              <Route
                path={`${SisfologRoutes.checklistManagement}`}
                element={<MasterDataChecklistPage />}
              />
              <Route
                path={`${SisfologRoutes.warehousePicManagement}`}
                element={<WarehouseItemsPICPage />}
              />
              {/* <Route
                path={`${SisfologRoutes.management_material_view}/:id`}
                element={<MaterialViewPage />}
              /> */}
              <Route
                path={`${SisfologRoutes.management_material_view}/:id/edit`}
                element={<MaterialEditFormPage />}
              />
              <Route
                path={`${SisfologRoutes.fileManagement}`}
                element={<FileManagementPage />}
              />
              <Route
                path={`${SisfologRoutes.pesudManagement}`}
                element={<PesudManagementPage />}
              />
              <Route
                path={`${SisfologRoutes.pesudManagement}/view/:id`}
                element={<PesudManagementDetailPage />}
              />
              <Route
                path={`${SisfologRoutes.management_warehouse_workflow}`}
                element={<WorkflowWarehousePage />}
              />
              <Route
                path={`${SisfologRoutes.pesudMaster}`}
                element={<PesudMasterPage />}
              />
              <Route
                path={`${SisfologRoutes.pesudMaster}/add`}
                element={<PesudLongFormPage />}
              />
              <Route
                path={`${SisfologRoutes.pesudMaster}/edit/:id`}
                element={<PesudLongFormPage />}
              />
              <Route
                path={`${SisfologRoutes.pesudMaster}/view/:id`}
                element={<PesudDetailPage />}
              />
              <Route
                path={`${SisfologRoutes.sucadMaster}`}
                element={<SucadMasterPage />}
              />
              <Route
                path={`${SisfologRoutes.transactionManagement}`}
                element={<TransactionPage />}
              />

              <Route
                path={`${SisfologRoutes.report}`}
                element={<ReportPage />}
              />
              <Route
                path={`${SisfologRoutes.notification}`}
                element={<NotificationPage />}
              />
              <Route
                path={`${SisfologRoutes.management_account}`}
                element={<ManagementUserPage />}
              />
              <Route
                path={SisfologRoutes.management_workflow}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <ManagementWorkflowPage />
                  </UserGate>
                }
              />
              <Route
                path={`${SisfologRoutes.backup_restore}/:type`}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <BackupRestorePage />
                  </UserGate>
                }
              />
            </Route>
          </Route>
          <Route
            path={ListOfRoutes.loginpage}
            element={
              <Suspense fallback={<LoaderPage />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route path={SisfologRoutes.login} element={<LoginPage />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <StyledToastContainerPuspenerbal />
    </>
  );
}

export default App;
