import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { ISucadMasterGetRequest } from "types/endpoints/masterSucadData";
import { IMasterSucad } from "types/endpoints/sucad";

export const sucadMasterStoreAPI = createApi({
  reducerPath: "sucadMasterStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/master-sucad`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["SucadMasterList"],
  endpoints: (builder) => {
    return {
      getSucadMaster: builder.query<
        APaginationEntity<IMasterSucad[]>,
        BackendPaginationRequestObject<Partial<ISucadMasterGetRequest>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IMasterSucad[]>) =>
          response.data,
        providesTags: ["SucadMasterList"],
      }),

      getSucadMasterById: builder.query<IMasterSucad, Partial<IMasterSucad>>({
        query: ({ id }) => {
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: any) => response.data,
        providesTags: ["SucadMasterList"],
      }),

      getSucadMasterPagination: builder.query<
        APaginationEntity<IMasterSucad[]>,
        BackendPaginationRequestObject<Partial<IMasterSucad>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IMasterSucad[]>) => {
          const { entities, meta } = response.data;
          let startIndex = (meta.page - 1) * meta.offset;
          let no = startIndex;
          const materials = entities.map(({ id, ...rest }) => {
            no++;
            return {
              ...rest,
              id,
              noMaterial: no,
            };
          });
          return {
            entities: materials,
            meta,
          };
        },
        providesTags: (_, __, { id }) => [
          "SucadMasterList",
          { type: "SucadMasterList", id },
        ],
      }),

      createSucadMaster: builder.mutation<
        Pick<IMasterSucad, "id">[],
        Partial<IMasterSucad>
      >({
        query: (obj) => {
          return {
            url: "",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["SucadMasterList"],
      }),

      updateSucadMaster: builder.mutation<
        BackendDataShape<object>,
        Partial<IMasterSucad>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["SucadMasterList"],
      }),

      deleteSucadMaster: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["SucadMasterList"],
      }),
    };
  },
});

export const {
  useCreateSucadMasterMutation,
  useGetSucadMasterByIdQuery,
  useGetSucadMasterQuery,
  useGetSucadMasterPaginationQuery,
  useDeleteSucadMasterMutation,
  useUpdateSucadMasterMutation,
  util: { resetApiState: resetSucadMasterStoreAPI },
} = sucadMasterStoreAPI;
