import React, { ReactNode } from "react";
import { Card } from "reactstrap";

interface Props {
  style?: React.CSSProperties;
  children?: ReactNode;
}

const TableContainer: React.FunctionComponent<Props> = ({
  children,
  style,
}) => {
  return (
    <Card
      style={{
        borderRadius: "8px",
        paddingTop: "1em",
        // height: "calc(100% - 26px)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      {children}
    </Card>
  );
};

export default TableContainer;
