import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "../../types";
import { AMaterial } from "../../types/endpoints/material/material";

export const materialStoreAPI = createApi({
  reducerPath: "materialStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MaterialList"],
  endpoints: (builder) => {
    return {
      getMaterial: builder.query<
        APaginationEntity<AMaterial[]>,
        BackendPaginationRequestObject<Partial<AMaterial>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/asset" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<AMaterial[]>) =>
          response.data,
        providesTags: ["MaterialList"],
      }),
      getMaterialPagination: builder.query<
        APaginationEntity<AMaterial[]>,
        BackendPaginationRequestObject<Partial<AMaterial>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/asset" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<AMaterial[]>) => {
          const { entities, meta } = response.data;
          let startIndex = (meta.page - 1) * meta.offset;
          let no = startIndex;
          const materials = entities.map(({ noMaterial, id, ...rest }) => {
            no++;
            return {
              ...rest,
              id,
              noMaterial: no,
            };
          });
          return {
            entities: materials,
            meta,
          };
        },
        providesTags: (_, __, { id }) => [
          "MaterialList",
          { type: "MaterialList", id },
        ],
      }),

      createMaterial: builder.mutation<
        Pick<AMaterial, "id">[],
        Partial<AMaterial>
      >({
        query: (obj) => {
          return {
            url: "/asset",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["MaterialList"],
      }),

      updateMaterial: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<AMaterial>;
        }
      >({
        query: ({ data, id }) => {
          let params = `?id=${id}`;
          return {
            url: "/asset" + params,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MaterialList"],
      }),

      deleteMaterial: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          let params = `?id=${id}`;
          return {
            url: `/asset` + params,
            method: "DELETE",
          };
        },
        invalidatesTags: ["MaterialList"],
      }),
    };
  },
});

export const {
  useCreateMaterialMutation,
  useGetMaterialPaginationQuery,
  useDeleteMaterialMutation,
  useGetMaterialQuery,
  useUpdateMaterialMutation,
  util: { resetApiState: resetMaterialStoreAPI },
} = materialStoreAPI;
