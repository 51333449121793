import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import {
  APaginationEntity,
  BackendPaginationRequestObject,
  NewBackendDataShape,
} from "../../types";
import { DailyCheckupItem } from "../../types/endpoints/dailycheckup/dailycheckup";

export const dailyCheckupStoreAPI = createApi({
  reducerPath: "dailyCheckupStore",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Assets"],
  endpoints: (builder) => {
    return {
      getDailyCheckup: builder.query<
        APaginationEntity<DailyCheckupItem[]>,
        BackendPaginationRequestObject<{ id?: number }>
      >({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `/asset/${id}/daily_checkup` + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["Assets"],
        transformResponse: (
          res: NewBackendDataShape<APaginationEntity<DailyCheckupItem[]>>,
        ) => {
          return res.data;
        },
      }),
    };
  },
});

export const {
  useGetDailyCheckupQuery,
  util: { resetApiState: resetDailyCheckupStore },
} = dailyCheckupStoreAPI;
