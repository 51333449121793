import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";

import {
  convertToEncodedURL,
  jsonToFormData,
  objectToFormData,
} from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  APesudMaster,
  APesudMasterResponse,
} from "types/endpoints/masterPesudData";

export const pesudMasterStoreAPI = createApi({
  reducerPath: "pesudMasterStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/master-pesud`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PesudMasterList"],
  endpoints: (builder) => {
    return {
      restorePesudMaster: builder.mutation<
        object,
        {
          file: File[];
        }
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/restore",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudMasterList"],
      }),

      getPesudMaster: builder.query<
        APaginationEntity<APesudMaster[]>,
        BackendPaginationRequestObject<Partial<APesudMaster>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APesudMaster[]>) =>
          response.data,
        providesTags: ["PesudMasterList"],
      }),

      getPesudMasterAll: builder.query<APesudMaster[], object>({
        query: () => {
          const url = "/all";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<APesudMaster[]>) =>
          response.data,
        providesTags: ["PesudMasterList"],
      }),

      getPesudMasterById: builder.query<
        APesudMasterResponse,
        Partial<APesudMasterResponse>
      >({
        query: ({ id }) => {
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: any) => response,
        providesTags: ["PesudMasterList"],
      }),

      getPesudMasterPagination: builder.query<
        APaginationEntity<APesudMaster[]>,
        BackendPaginationRequestObject<Partial<APesudMaster>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APesudMaster[]>) => {
          const { entities, meta } = response.data;
          let startIndex = (meta.page - 1) * meta.offset;
          let no = startIndex;
          const materials = entities.map(({ noMaterial, id, ...rest }) => {
            no++;
            return {
              ...rest,
              id,
              noMaterial: no,
            };
          });
          return {
            entities: materials,
            meta,
          };
        },
        providesTags: (_, __, { id }) => [
          "PesudMasterList",
          { type: "PesudMasterList", id },
        ],
      }),

      createPesudMaster: builder.mutation<
        Pick<APesudMaster, "id">[],
        Partial<APesudMaster>
      >({
        query: (obj) => {
          const formData = objectToFormData(obj);
          return {
            url: "",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudMasterList"],
      }),

      updatePesudMaster: builder.mutation<
        BackendDataShape<object>,
        Partial<APesudMaster>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PesudMasterList"],
      }),

      deletePesudMaster: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PesudMasterList"],
      }),
    };
  },
});

export const {
  useRestorePesudMasterMutation,
  useCreatePesudMasterMutation,
  useGetPesudMasterQuery,
  useGetPesudMasterPaginationQuery,
  useDeletePesudMasterMutation,
  useUpdatePesudMasterMutation,
  useGetPesudMasterByIdQuery,
  useGetPesudMasterAllQuery,
  util: { resetApiState: resetPesudMasterStoreAPI },
} = pesudMasterStoreAPI;
