import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { UsedAPI } from "api/config";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { IParamStockSucad, IStockSucad } from "types/endpoints/sucad";

export const stockSucadStoreAPI = createApi({
  reducerPath: "stockSucadStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/stock`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["StockSucadList"],
  endpoints: (builder) => {
    return {
      getStockSucad: builder.query<
        APaginationEntity<IStockSucad[]>,
        BackendPaginationRequestObject<Partial<IParamStockSucad>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IStockSucad[]>) =>
          response.data,
        providesTags: ["StockSucadList"],
      }),
    };
  },
});

export const {
  useGetStockSucadQuery,
  util: { resetApiState: resetStockSucadStoreAPI },
} = stockSucadStoreAPI;
