import { createUseStyles } from "react-jss";
import { COLORS } from "../../theme";
import { extraLarge, extraExtraLarge } from "../../../assets/breakpoints";

export const useTableActionStyles = createUseStyles({
  btnContainer: {
    display: "flex",
    gap: "0.8rem",
  },
  mapBtn: {
    border: 0,
    backgroundColor: "#20C997",
    padding: "8px 16px",
    borderRadius: "0.65rem",
    width: "fit-content",
    whiteSpace: "nowrap",
    color: "white",
    [extraLarge]: {
      fontSize: "8px",
    },
    [extraExtraLarge]: {
      fontSize: "10px",
    },
    "&:hover": {
      opacity: 0.75,
    },
  },
  mapIconBtn: {
    border: 0,
    backgroundColor: "transparent",

    color: COLORS.blue_3,
    padding: "8px",
    borderRadius: "0.65rem",
    fontSize: "1.8rem",
    "& > svg": {
      fill: COLORS.blue_3,
    },
    "&:hover": {
      opacity: 0.75,
      color: "white",
      backgroundColor: COLORS.blue_3,
      "& > svg": {
        fill: "white",
      },
    },
  },
  qrBtn: {
    border: 0,
    backgroundColor: COLORS.asphalt,
    padding: "1rem 1.8rem",
    width: "fit-content",
    whiteSpace: "nowrap",
    [extraLarge]: {
      fontSize: "8px",
    },
    [extraExtraLarge]: {
      fontSize: "10px",
    },
    color: COLORS.white,
    borderRadius: "0.65rem",
    "&:hover": {
      opacity: 0.75,
    },
  },
  penBtn: {
    border: 0,
    backgroundColor: "transparent",
    color: COLORS.blue_1,
    padding: "8px",
    borderRadius: "0.65rem",
    fontSize: "1.8rem",
    "& > svg": {
      fill: COLORS.blue_1,
    },
    "&:hover": {
      opacity: 0.75,
      color: "white",
      backgroundColor: COLORS.blue_1,
      "& > svg": {
        fill: "white",
      },
    },
  },
  bindBtn: {
    border: 0,
    backgroundColor: "#20C997",
    padding: "8px 16px",
    borderRadius: "0.65rem",
    color: "white",
    [extraLarge]: {
      fontSize: "8px",
    },
    [extraExtraLarge]: {
      fontSize: "10px",
    },
    "&:hover": {
      opacity: 0.75,
    },
  },
  editBtn: {
    border: 0,
    backgroundColor: "#3699FF",
    padding: "1rem 1.8rem",
    width: "fit-content",
    whiteSpace: "nowrap",
    color: "white",
    [extraLarge]: {
      fontSize: "8px",
    },
    [extraExtraLarge]: {
      fontSize: "10px",
    },
    borderRadius: "0.65rem",
    "&:hover": {
      opacity: 0.75,
    },
  },
  deleteBtn: {
    border: 0,
    backgroundColor: "transparent",
    color: "#F05454",
    padding: "6px 8px",
    borderRadius: "4px",
    [extraLarge]: {
      fontSize: "8px",
    },
    [extraExtraLarge]: {
      fontSize: "10px",
    },
    "&:hover": {
      opacity: 0.75,
      color: "white",
      backgroundColor: "#F05454",
    },
  },
  deleteBtnSchedule: {
    border: 0,
    backgroundColor: "transparent",
    color: COLORS.red,
    padding: "6px",
    borderRadius: "4px",
    fontSize: "20px",
    float: "right",
    "&:hover": {
      opacity: 0.75,
      color: COLORS.white,
      backgroundColor: COLORS.red,
    },
  },
  disabledBtn: {
    border: 0,
    backgroundColor: "transparent",
    color: COLORS.dark_grey_1,
    padding: "6px",
    borderRadius: "4px",
    fontSize: "20px",
    float: "right",
    cursor: "not-allowed",
  },
  groupContainer: {
    display: "flex",
    maxHeight: 100,
    // width: "7vw",
    overflowX: "hidden",
    overflowY: "auto",
    gap: 6,
    flexWrap: "wrap",
    "& > button": {
      cursor: "default",
      border: 0,
      borderRadius: "2rem",
      padding: "0.5rem 1.5rem",
      backgroundColor: COLORS.blue_3,
      color: "white",
      whiteSpace: "nowrap",
      "&:hover": {
        // opacity: 0.8,
      },
    },
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      border: "3.2px solid transparent",
    },
  },
  aDot: {
    height: "1.35rem",
    width: "1.35rem",
    borderRadius: "50%",
    display: "inline-block",
  },
  linkLikeBtn: {
    border: 0,
    backgroundColor: "transparent",
    textDecoration: "underline",
    weight: 400,
    color: "#0d6efd",
    "&:hover": {
      opacity: 0.7,
    },
  },

  applyFilterBtn: {
    width: "160px",
    float: "right",
    padding: "8px 16px 8px 16px",
    margin: "12px 0 6px 0 ",
    background: COLORS.blue_2,
    border: "0",
    radius: "4px",
  },
  loadingContainer: {
    minHeight: "100px",
  },
  resetBtn: {
    border: 0,
    backgroundColor: COLORS.yellow,
    padding: "1rem 1.8rem",
    [extraLarge]: {
      fontSize: "8px",
    },
    [extraExtraLarge]: {
      fontSize: "10px",
    },
    color: COLORS.white,
    borderRadius: "0.65rem",
    "&:hover": {
      opacity: 0.75,
    },
  },
  nextButton: {
    background: COLORS.secondaryBlue,
    border: "0",
    padding: "8px 40px",
    float: "right",
    "&:hover": {
      opacity: 0.75,
      backgroundColor: COLORS.secondaryBlue,
      color: COLORS.black_1,
    },
  },
  backButton: {
    marginRight: "1em",
    background: COLORS.green_2,
    border: "0",
    padding: "8px 40px",
    "&:hover": {
      opacity: 0.75,
      backgroundColor: COLORS.green_2,
      color: COLORS.black_1,
    },
  },
  cancelButton: {
    marginRight: "1em",
    background: COLORS.light_grey_1,
    color: COLORS.black_3,
    border: "0",
    padding: "8px 40px",
    "&:hover": {
      opacity: 0.75,
      backgroundColor: COLORS.light_grey_1,
      color: COLORS.black_1,
    },
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0em 1em 1em 1em ",
  },
  addBtn: {
    color: "#3699FF",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    background: "transparent",
    border: "none",
    padding: "0px",
    letterSpacing: "0.02em",
    width: "fit-content",
    whiteSpace: "nowrap",
  },
  requiredText: {
    color: COLORS.red_1_puspenerbal,
    fontSize: 9,
  },
  disabledButton: {
    border: 0,
    backgroundColor: COLORS.grey,
    padding: "1rem 1.8rem",
    [extraLarge]: {
      fontSize: "8px",
    },
    [extraExtraLarge]: {
      fontSize: "10px",
    },
    color: COLORS.white,
    borderRadius: "0.65rem",
    cursor: "not-allowed",
  },
});
