import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { UsedAPI } from "api/config";

import { convertToEncodedURL } from "assets/usefulFunctions";
import { APaginationEntity, BackendPaginationRequestObject } from "types";

import { IWarehouse } from "types/endpoints/warehouse";

export const warehouseStoreAPI = createApi({
  reducerPath: "warehouseStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/gudang`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["WarehouseList"],
  endpoints: (builder) => {
    return {
      getWarehouse: builder.query<
        APaginationEntity<IWarehouse[]>,
        BackendPaginationRequestObject<Partial<IWarehouse>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: APaginationEntity<IWarehouse[]>) =>
          response,
        providesTags: ["WarehouseList"],
      }),
    };
  },
});

export const {
  useGetWarehouseQuery,
  util: { resetApiState: resetWarehouseStore },
} = warehouseStoreAPI;
